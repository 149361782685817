import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from '../components/Title';
import * as React from "react";
import { useEffect, useState } from "react";
import { Storefront } from "../models/StorefrontResponse";
import { DropzoneArea } from "mui-file-dropzone";
import { Alert, Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useS3FileS3Upload } from "../hooks/useS3FileUpload";
import { useTSGUser } from "../contexts/TSGUserContext";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
    storefront: Storefront
}

const FileUpload = (props: Props) => {
    const [files, setFiles] = useState<File[]>([]);
    const [file, setFile] = useState<File>({} as File);
    const [startUpload, setStartUpload] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const { tsgUser } = useTSGUser();
    const { S3UploadCompleted, S3UploadError, S3UploadProgress } = useS3FileS3Upload(file, startUpload, tsgUser?.id || "", "");

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/FileUpload']);
        _hsq.push(['trackPageView']);
    }, []);

    useEffect(() => {
        if (files.length > 0) {
            // Loop through files and write to console
            files.forEach((file) => {
                console.log("Selected File:", file.name);
            });
        }
    }, [files]);

    const uploadButtonClicked = () => {
        setErrorMessage("")
        if (files.length === 0) {
            setErrorMessage("Please select a file to upload before clicking the upload button.")
            return
        } else if (files.length == 1) {
            setFile(files[0])
            setStartUpload(true)
            return
        }

        setErrorMessage("Please select only one file to upload.")
    }

    const reset = () => {
        setFiles([])
        setFile({} as File)
        setErrorMessage("")
        setStartUpload(false)
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Title>File Upload</Title>
                        <Typography variant="body1" sx={{ mt: 1, mb:1 }}>Note: This feature should only be used if you have been instructed to upload a file.</Typography>
                        <DropzoneArea fileObjects={files} onChange={(files) => setFiles(files)} maxFileSize={100000000} />
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={uploadButtonClicked} sx={{ mt: 2, width: 200, background: props.storefront.activeColors.mainPage.pageButtonBackground }}>
                            Upload
                        </Button>
                        <Button variant="contained" onClick={reset} sx={{ mt: 2, width: 200, background: props.storefront.activeColors.mainPage.pageButtonBackground }}>
                            Reset
                        </Button>
                        </Stack>
                        {S3UploadProgress > 0 &&
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                                <Typography variant="body1" sx={{ width: 120 }}>Uploading file...</Typography>
                                <Box sx={{ width: '81%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={S3UploadProgress} />
                                </Box>
                            </Stack>
                        }
                        {errorMessage !== "" && <Alert variant="filled" severity="error" sx={{ ml: 1, mr: 1, mt: 1 }}>{errorMessage}</Alert>}
                        {S3UploadError == "" && S3UploadCompleted && <Alert variant="filled" severity="info" sx={{ mt: 2 }}>The file was uploaded successfully!</Alert>}
                        {S3UploadError !== "" && <Alert variant="filled" severity="error" sx={{ ml: 1, mr: 1, mt: 1 }}>{S3UploadError}</Alert>}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FileUpload;
