import {useState, useEffect} from 'react';
import { APIEndpoint, EndpointType, httpPost } from '../utils/apiService'
import { BlueprintCountResponse } from '../models/ZipCodeCountsResponse';
import { QueryBlueprint } from '../models/Blueprint';

const apiURL =  APIEndpoint(EndpointType.ZipCodeCounts);

// If the suppressionListID is not empty, we are suppressing only that order. If it is empty, we are obeying the suppressOrders flag.
export const useBlueprintCounts = (queryBlueprint: QueryBlueprint | undefined, suppressOrders: boolean, customerID: string, suppressionListID: string) => {
    const [blueprintCount, setBlueprintCount] = useState(0)
    const [blueprintCountError, setBlueprintCountError] = useState("")
    const [blueprintCountLoading, setBlueprintCountLoading] = useState(false)
    const [blueprintSuppressionCount, setBlueprintSuppressionCount] = useState(0)

    useEffect(() => {        
        if (!queryBlueprint) {
            setBlueprintCount(0)
            return
        }

        let url = apiURL
        if (suppressOrders) {
            if (customerID === "") {
                setBlueprintCountError("Customer ID is required when suppressing orders.")
                return
            }
            url += `?suppressOrders=true&customerID=${customerID}`
        }

        if (suppressionListID  != "") {
            url += `&suppression-id=${suppressionListID}`
        }

        setBlueprintCount(0)
        setBlueprintCountLoading(true)
        setBlueprintCountError("")
        httpPost(url, JSON.stringify(queryBlueprint))
            .then((data) => {
                const response = data as BlueprintCountResponse;
                
                if (response.status === 'error') {
                    setBlueprintCountError(response.errorMessage)
                    setBlueprintCount(0)
                    setBlueprintSuppressionCount(0)
                } else {
                    setBlueprintCount(response.count)
                    setBlueprintSuppressionCount(response.suppressionCount)
                }
            })
            .catch((error) => setBlueprintCountError(error))
            .finally(() => setBlueprintCountLoading(false))

    }, [queryBlueprint]);

    return { blueprintCount, blueprintCountLoading, blueprintCountError, blueprintSuppressionCount };
};