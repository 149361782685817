import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
//import HelpIcon from '@mui/icons-material/Help';
//import InfoIcon from "@mui/icons-material/Info";
//import MapIcon from "@mui/icons-material/Map"
import DashboardIcon from '@mui/icons-material/Dashboard';
//import { getEnvironment } from '../utils/apiService'
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Box, Link, Stack } from "@mui/material";
import { Storefront } from "../models/StorefrontResponse";
import WalletIcon from '@mui/icons-material/Wallet';
//import FiberNewIcon from '@mui/icons-material/FiberNew';
//import CalculateIcon from '@mui/icons-material/Calculate';
//import PaidIcon from '@mui/icons-material/Paid';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ApiIcon from '@mui/icons-material/Api';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HouseIcon from '@mui/icons-material/House';
interface Props {
  storefront: Storefront
  features: string[]
  isTSG: boolean
}

export const MainListItems = (props: Props) => {
  const navigate = useNavigate();
  //const environment = getEnvironment();

  // const openInNewTab = (url: string) => {
  //   window.open(url, "_blank", "noreferrer");
  // };

  return (

    <React.Fragment>

      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/mywallet");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <WalletIcon />
        </ListItemIcon>
        <ListItemText primary="Wallet" />
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/myorders");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="My Purchases" />
      </ListItemButton>

      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/products");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <AddShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Lead Store" />
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/leadenhancement");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <GroupAddIcon />
        </ListItemIcon>
        <ListItemText primary="Lead Enhancement" />
      </ListItemButton>

      {/*environment == "dev" &&
        <ListItemButton
          sx={{

          }}
          onClick={() => {
            navigate("/leadsvisualizer");
          }}
        >
          <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
            <MapIcon />
          </ListItemIcon>
          <ListItemText primary="Lead Visualizer" />
        </ListItemButton>
      */}

      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/lookup");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <PersonSearchIcon />
        </ListItemIcon>
        <ListItemText primary="Locate People" />
        {/* <ListItemIcon sx={{color: "#de1010"}}>
              <FiberNewIcon sx={{ml:2}} />
            </ListItemIcon> */}
      </ListItemButton>

      {/*<ListItemButton
            sx={{
              
            }}
            onClick={() => {
              navigate("/calculators");
            }}
          >
            <ListItemIcon sx={{color: props.storefront.activeColors.leftMenu.icon}}>
              <CalculateIcon />
            </ListItemIcon>
            <ListItemText primary="Lead Calculators" />
        </ListItemButton>*/}
      {/* { props.storefront.storeName == 'tsg' &&
        <ListItemButton
            sx={{
              
            }}
            onClick={() => openInNewTab("https://www.theshare.group/leads-for-investors-wholesalers")}
          >
            <ListItemIcon sx={{color: props.storefront.activeColors.leftMenu.icon}}>
              <PaidIcon />
            </ListItemIcon>
            <ListItemText primary="Investors" />
        </ListItemButton>
      } */}
      {/* {(props.features.indexOf('list_builder') > -1 || props.isTSG) && */}
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/listbuilder");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <QueryStatsIcon />
        </ListItemIcon>
        <ListItemText primary="List Builder" />
        {/* <ListItemIcon sx={{color: "#de1010"}}>
                <FiberNewIcon sx={{ml:2}} />
            </ListItemIcon> */}
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/mortgage");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <HouseIcon />
        </ListItemIcon>
        <ListItemText primary="Mortgage Leads" />
        {/* <ListItemIcon sx={{color: "#de1010"}}>
              <FiberNewIcon sx={{ml:3}} />
          </ListItemIcon> */}
      </ListItemButton>


      {(props.features.indexOf('radius_search') > -1 || props.isTSG) &&
        <ListItemButton
          sx={{

          }}
          onClick={() => {
            navigate("/radiussearch");
          }}
        >
          <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
            <TravelExploreIcon />
          </ListItemIcon>
          <ListItemText primary="Radius Search" />
        </ListItemButton>
      }

      {/* } */}
      {props.storefront.storeName == 'agentlegend' &&
        <Stack direction="column">
          <Stack direction="row">
            <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon, ml: 2, mt: 1.2 }}>
              <ContactSupportIcon />
            </ListItemIcon>
            <Link href="mailto:support@agentlegend.com?subject=Question from AgentLegend Lead Store" sx={{ color: props.storefront.activeColors.mainPage.headerText, ml: 0, mt: 1, fontSize: 15 }} style={{ textTransform: 'none' }}>Product Support</Link>
          </Stack>
        </Stack>
      }
    </React.Fragment>
  );
};

export const FeatureListItems = (props: Props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {props.features.indexOf('list_counts') > -1 &&
        <ListItemButton
          sx={{

          }}
          onClick={() => {
            navigate("/zipcodecounts");
          }}
        >
          <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="List Counts" />
        </ListItemButton>
      }
    </React.Fragment>
  );
};

export const SalesListItems = (props: Props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/Customers");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/zipcodecounts");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="List Counts" />
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/neworder");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <AddShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Place New Order" />
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/storeproducts");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemButton>


      {/* <ListItemButton
          sx={{
            
          }}
          onClick={() => {
            navigate("/faq");
          }}
        >
          <ListItemIcon sx={{color: props.storefront.activeColors.leftMenu.icon}}>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
      </ListItemButton> */}
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/salesdashboard");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Sales Dashboard" />
      </ListItemButton>
      <ListItemButton
        sx={{

        }}
        onClick={() => {
          navigate("/walletmanagement");
        }}
      >
        <ListItemIcon sx={{ color: props.storefront.activeColors.leftMenu.icon }}>
          <WalletIcon />
        </ListItemIcon>
        <ListItemText primary="Wallet Management" />
      </ListItemButton>
      {/* <ListItemButton
          sx={{
            backgroundColor: (theme) =>
              window.location.pathname === "/users"
                ? theme.palette.primary.light
                : "transparent",
          }}
          onClick={() => {
            navigate("/users");
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
      </ListItemButton> */}
    </React.Fragment>
  );
};

export const StoreManagerListItems = (showSFManager: boolean, showOrders: boolean, storefront: Storefront) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {showOrders
        ? <ListItemButton
          sx={{

          }}
          onClick={() => {
            navigate("/orders");
          }}
        >
          <ListItemIcon sx={{ color: storefront.activeColors.leftMenu.icon }}>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItemButton>
        : <Box />
      }

      {/* {showOrders
        ? <ListItemButton
              sx={{
                
              }}
              onClick={() => {
                navigate("/pendingorders");
              }}
            >
              <ListItemIcon sx={{color: props.storefront.activeColors.leftMenu.icon}}>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Pending Orders" />
          </ListItemButton>
        : <Box/>
      } */}

      {showSFManager
        ? <ListItemButton
          sx={{

          }}
          onClick={() => {
            navigate("/storefront");
          }}
        >
          <ListItemIcon sx={{ color: storefront.activeColors.leftMenu.icon }}>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText primary="Storefront Manager" />
        </ListItemButton>
        : <Box />
      }

    </React.Fragment>
  );
}

export const APIManagerListItems = (storefront: Storefront) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ListItemButton onClick={() => { navigate("/apilogs"); }}>
        <ListItemIcon sx={{ color: storefront.activeColors.leftMenu.icon }}>
          <ApiIcon />
        </ListItemIcon>
        <ListItemText primary="API Viewer" />
      </ListItemButton>
    </React.Fragment>
  );
}

export const NoAdminListItems = () => {
  return (
    <React.Fragment />
  );
}
