import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { User, UsersResponse } from '../models/UsersResponse'

const apiURL =  APIEndpoint(EndpointType.Users);

export const useUser = (userID: string) => {
    const [customer, setUser] = useState<User>()
    const [customerError, setError] = useState("")
    const [customerLoading, setLoading] = useState(false)

    useEffect(() => {        
        if (userID == "" || userID == "unknown" || userID == undefined) {
            return
        }

        setLoading(true)
        setError("")
        httpGet(apiURL + "/" + userID)
            .then((data) => {
                const usersResponse = data as UsersResponse;
                
                if (usersResponse.status == "error") {
                    setError(usersResponse.errorMessage)
                    setUser({} as User)
                    return
                }

                if (usersResponse.users) {
                    if (usersResponse.users.length > 0) {
                        setUser(usersResponse.users[0]);
                    }
                } else {
                    setUser({} as User);
                }
                
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false))

    }, [userID]);

    return { customer, customerLoading, customerError };
};