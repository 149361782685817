import { Auth } from "aws-amplify";
import isNetworkError from 'is-network-error';
import { jwtDecode } from "jwt-decode";

const Environment: string = getEnvironment()

export function getEnvironment() {
    let env = process.env.REACT_APP__ENV;

    if (env == undefined || env == "") {
        env = "prod"
        console.log("no REACT_APP__ENV environment variable set...defaulting to prod")
    }

    return env
}

function parseURLMeat(url: string): string {
    const idx = url.indexOf(".amazonaws.com")

    if (idx < 0) {
        return url
    }

    return url.substring(idx + 14)
}

const getAuthToken = async () => {
    const currentSession = Auth.currentSession()
    let accessToken = ""

    if (currentSession == null) {
        window.location.replace(window.location.origin + '/signin')
    } else {
        const token = (await currentSession).getAccessToken()
        accessToken = token.getJwtToken()
    }

    const expiredToken = checkTokenExpiration(accessToken);
    if (expiredToken != "") {
        console.log("Token is expired: ", expiredToken)
        window.location.replace(window.location.origin + '/signin')
        return ""
    }

    return accessToken
}

export const httpGet = async (apiURL: string) => {
    try {
        console.log('[GET] ' + parseURLMeat(apiURL));

        const accessToken = await getAuthToken()
        if (accessToken == "") {
            return
        }

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            headers: {
                'environment': Environment,
                'authorization': accessToken
            }
        });
        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            if (apiResponse.status >= 400 && apiResponse.status < 500) {
                window.location.replace(window.location.origin + '/signin')
            }
            throw apiResponse.statusText
        } else {
            const json = await apiResponse.json();
            console.log('[GET-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
            if (json.status == 'error') {
                console.log('Error is: ' + json.errorMessage);
            }

            return json
        }
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpGetData = async (apiURL: string) => {
    try {
        console.log('[GET-DATA] ' + parseURLMeat(apiURL));

        const accessToken = await getAuthToken()
        if (accessToken == "") {
            return
        }

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            headers: {
                'environment': Environment,
                'authorization': accessToken,
                'Content-Type': 'application/pdf'
            }
        });

        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            if (apiResponse.status >= 400 && apiResponse.status < 500) {
                window.location.replace(window.location.origin + '/signin')
            }
            throw apiResponse.statusText
        } else {
            const blob = await apiResponse.blob();
            console.log('Blob: ', blob)
            console.log(blob.size)
            console.log('[GET-DATA-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + blob.size + ' bytes)');
            return blob
        }
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message + ' - ' + apiURL)
        } else {
            console.log("Non-Network error: " + message + ' - ' + apiURL)
        }
    }
}

export const httpGetNoAuth = async (apiURL: string) => {
    try {
        console.log('[GET-NOAUTH] ' + parseURLMeat(apiURL));

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            headers: {
                'environment': Environment
            }
        });
        const json = await apiResponse.json();
        console.log('[GET-NOAUTH-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status == 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPost = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[POST] ' + parseURLMeat(apiURL));

        const accessToken = await getAuthToken()
        if (accessToken == "") {
            return
        }

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'environment': Environment,
                'authorization': accessToken
            },
            body: jsonStr,
        });

        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            if (apiResponse.status >= 400 && apiResponse.status < 500) {
                window.location.replace(window.location.origin + '/signin')
            }
            throw apiResponse.statusText
        } else {
            const json = await apiResponse.json();
            console.log('[POST-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
            if (json.status == 'error') {
                console.log('Error is: ' + json.errorMessage);
            }
    
            return json
        }


    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPostFormData = async (apiURL: string, formData: FormData) => {
    try {
        console.log('[POST] ' + parseURLMeat(apiURL));

        const accessToken = await getAuthToken()
        if (accessToken == "") {
            return
        }

        const apiResponse = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'environment': Environment,
                'authorization': accessToken,
            },
            body: formData,
        });

        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            if (apiResponse.status >= 400 && apiResponse.status < 500) {
                window.location.replace(window.location.origin + '/signin')
            }
            throw apiResponse.statusText
        } else {
            const json = await apiResponse.json();
            console.log('[POST-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
            if (json.status == 'error') {
                console.log('Error is: ' + json.errorMessage);
            }
    
            return json
        }
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPut = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[PUT] ' + parseURLMeat(apiURL));

        const accessToken = await getAuthToken()
        if (accessToken == "") {
            return
        }

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            method: 'PUT',
            headers: {
                'environment': Environment,
                'authorization': accessToken
            },
            body: jsonStr,
        });
        
        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            if (apiResponse.status >= 400 && apiResponse.status < 500) {
                window.location.replace(window.location.origin + '/signin')
            }
            throw apiResponse.statusText
        } else {
            console.log('[PUT-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + apiResponse.status + ')');
            const json = await apiResponse.json();
            console.log('[PUT-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
            if (json.status == 'error') {
                console.log('Error is: ' + json.errorMessage);
            }
    
            return json
        }
    } catch (error) {
        console.log("caught network error: " + error)
        throw new Error('Network Error - ' + error)
        // let message = "Unknown Error"
        // if (error instanceof Error) {
        //     message = error.message
        // }

        // if (isNetworkError(error)) {
        //     throw new Error('Network Error - ' + message)
        // } else {
        //     console.log("Non-Network error: " + message)
        // }
    }
}

export const httpPutNoAuth = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[PUT-NOAUTH] ' + parseURLMeat(apiURL));

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            method: 'PUT',
            headers: {
                'environment': Environment,
            },
            body: jsonStr,
        });
        const json = await apiResponse.json();
        console.log('[PUT-NOAUTH-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status == 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPostNoAuth = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[POST-NOAUTH] ' + parseURLMeat(apiURL));

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'environment': Environment,
            },
            body: jsonStr,
        });
        const json = await apiResponse.json();
        console.log('[POST-NOAUTH-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status == 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpDelete = async (apiURL: string) => {
    try {
        console.log('[DELETE] ' + parseURLMeat(apiURL));

        const accessToken = await getAuthToken()
        if (accessToken == "") {
            return
        }
        
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            method: 'DELETE',
            headers: {
                'environment': Environment,
                'authorization': accessToken
            }
        });

        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            if (apiResponse.status >= 400 && apiResponse.status < 500) {
                window.location.replace(window.location.origin + '/signin')
            }
            throw apiResponse.statusText
        } else {
            const json = await apiResponse.json();
            console.log('[DELETE-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
            if (json.status == 'error') {
                console.log('Error is: ' + json.errorMessage);
            }
    
            return json
        }
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export enum EndpointType {
    Announcements,
    Blueprints,
    Checkout,
    Companies,
    Coupon,
    DataEnhancement,
    ErrorLog,
    ExportOrder,
    Feedback,
    Lookup,
    MapboxForwardGeocoding,
    MyOrders,
    Orders,
    Questionnaire,
    OptOut,
    PendingOrders,
    Products,
    Query,
    StoreCart,
    Storefront,
    Subscriptions,
    Suppressions,
    Users,
    RadiusCounts,
    Upload,
    Values,
    WalletBalance,
    WalletTransactions,
    Websocket,
    ZipCodeCounts,
}

// API Endpoint URLs
const BlueprintsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/blueprints';
const BlueprintsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/blueprints';
const companiesDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/companies';
const companiesProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/companies';
const myOrdersDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/orders/customer/';
const myOrdersProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/orders/customer/';
const OrdersDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/orders';
const OrdersProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/orders';
const PendingOrdersDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/pendingorders';
const PendingOrdersProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/pendingorders';
const UsersDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/users';
const UsersProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/users';
const AnnouncementsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/announcements';
const AnnouncementsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/announcements';
const ValuesDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/values';
const ValuesProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/values';
const RadiusCountsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/radiuscounts';
const RadiusCountsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/radiuscounts';
const ZipCodeCountsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/zipcodecounts';
const ZipCodeCountsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/zipcodecounts';
const StorefrontDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/store/front';
const StorefrontProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/store/front';
const StoreCartDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/store/cart';
const StoreCartProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/store/cart';
const StoreCouponProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/store/coupon';
const StoreCouponDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/store/coupon';
const StoreCheckoutProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/store/checkout';
const StoreCheckoutDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/store/checkout';
const SubscriptionsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/subscriptions';
const SubscriptionsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/subscriptions';
const ExportOrderProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/export';
const ExportOrderDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/export';
const ProductsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/products';
const ProductsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/products';
const QuestionnaireProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/questionnaires';
const QuestionnaireDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/questionnaires';
const FeedbackProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/feedback';
const FeedbackDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/feedback';
const WalletBalanceProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/wallet/balance';
const WalletBalanceDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/wallet/balance';
const WalletTransactionsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/wallet/transactions';
const WalletTransactionsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/wallet/transactions';
const ErrorLogProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/errorlog';
const ErrorLogDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/errorlog';
const QueryProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/query';
const QueryDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/query';
const LookupProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/lookup';
const LookupDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/lookup';
const WebsocketProd: string = 'wss://hu6pims0v3.execute-api.us-west-2.amazonaws.com/prod/';
const WebsocketDev: string = 'wss://2usnaow8h9.execute-api.us-west-2.amazonaws.com/dev/';
const DataEnhancementProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/dataenhancements';
const DataEnhancementDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/dataenhancements';
const SuppressionsProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/suppressions';
const SuppressionsDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/suppressions';  
const OptOutProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/optout';
const OpOutDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/optout';  
const UploadProd: string = 'https://u84eprn2ha.execute-api.us-west-2.amazonaws.com/prod/upload';
const UploadDev: string = 'https://1y1dzkpfdl.execute-api.us-west-2.amazonaws.com/dev/upload';  
const MapboxForwardGeocoding: string = 'https://api.mapbox.com/search/geocode/v6/forward';

export const APIEndpoint = (endpointType: EndpointType) => {
    switch (endpointType) {
        case EndpointType.Blueprints:
            if (Environment == 'prod') {
                return BlueprintsProd;
            } else {
                return BlueprintsDev;
            }
        case EndpointType.MyOrders:
            if (Environment == 'prod') {
                return myOrdersProd;
            } else {
                return myOrdersDev;
            }
        case EndpointType.Orders:
            if (Environment == 'prod') {
                return OrdersProd;
            } else {
                return OrdersDev;
            }
        case EndpointType.PendingOrders:
            if (Environment == 'prod') {
                return PendingOrdersProd;
            } else {
                return PendingOrdersDev;
            }
        case EndpointType.Users:
            if (Environment == 'prod') {
                return UsersProd;
            } else {
                return UsersDev;
            }
        case EndpointType.ZipCodeCounts:
            if (Environment == 'prod') {
                return ZipCodeCountsProd;
            } else {
                return ZipCodeCountsDev;
            }
        case EndpointType.RadiusCounts:
            if (Environment == 'prod') {
                return RadiusCountsProd;
            } else {
                return RadiusCountsDev;
            }
        case EndpointType.Announcements:
            if (Environment == 'prod') {
                return AnnouncementsProd;
            } else {
                return AnnouncementsDev;
            }

        case EndpointType.Values:
            if (Environment == 'prod') {
                return ValuesProd;
            } else {
                return ValuesDev;
            }

        case EndpointType.StoreCart:
            if (Environment == 'prod') {
                return StoreCartProd;
            } else {
                return StoreCartDev;
            }

        case EndpointType.Storefront:
            if (Environment == 'prod') {
                return StorefrontProd;
            } else {
                return StorefrontDev;
            }

        case EndpointType.Coupon:
            if (Environment == 'prod') {
                return StoreCouponProd;
            } else {
                return StoreCouponDev;
            }
        case EndpointType.Checkout:
            if (Environment == 'prod') {
                return StoreCheckoutProd;
            } else {
                return StoreCheckoutDev;
            }
        case EndpointType.Subscriptions:
            if (Environment == 'prod') {
                return SubscriptionsProd;
            } else {
                return SubscriptionsDev;
            }
        case EndpointType.ExportOrder:
            if (Environment == 'prod') {
                return ExportOrderProd;
            } else {
                return ExportOrderDev;
            }
        case EndpointType.Products:
            if (Environment == 'prod') {
                return ProductsProd;
            } else {
                return ProductsDev;
            }
        case EndpointType.Companies:
            if (Environment == 'prod') {
                return companiesProd;
            } else {
                return companiesDev;
            }
        case EndpointType.Questionnaire:
            if (Environment == 'prod') {
                return QuestionnaireProd;
            } else {
                return QuestionnaireDev;
            }
        case EndpointType.Feedback:
            if (Environment == 'prod') {
                return FeedbackProd;
            } else {
                return FeedbackDev;
            }
        case EndpointType.WalletBalance:
            if (Environment == 'prod') {
                return WalletBalanceProd;
            } else {
                return WalletBalanceDev;
            }
        case EndpointType.WalletTransactions:
            if (Environment == 'prod') {
                return WalletTransactionsProd;
            } else {
                return WalletTransactionsDev;
            }
        case EndpointType.ErrorLog:
            if (Environment == 'prod') {
                return ErrorLogProd;
            } else {
                return ErrorLogDev;
            }
        case EndpointType.Query:
            if (Environment == 'prod') {
                return QueryProd;
            } else {
                return QueryDev;
            }
        case EndpointType.Lookup:
            if (Environment == 'prod') {
                return LookupProd;
            } else {
                return LookupDev;
            }
        case EndpointType.Websocket:
            if (Environment == 'prod') {
                return WebsocketProd;
            } else {
                return WebsocketDev;
            }
        case EndpointType.DataEnhancement:
            if (Environment == 'prod') {
                return DataEnhancementProd;
            } else {
                return DataEnhancementDev;
            }
        case EndpointType.Suppressions:
            if (Environment == 'prod') {
                return SuppressionsProd;
            } else {
                return SuppressionsDev;
            }
        case EndpointType.OptOut:
            if (Environment == 'prod') {
                return OptOutProd;
            } else {
                return OpOutDev;
            }
        case EndpointType.Upload:
            if (Environment == 'prod') {
                return UploadProd;
            } else {
                return UploadDev;
            }
        case EndpointType.MapboxForwardGeocoding:
            return MapboxForwardGeocoding
    }
}

export function ParseQueryString(queryString: string): Map<string, string> {
    const queryParams = new Map<string, string>();

    // Remove the leading '?' if present
    if (queryString.startsWith('?')) {
        queryString = queryString.slice(1);
    }

    // Split the query string into individual key-value pairs
    const pairs = queryString.split('&');

    // Iterate through each pair and populate the map
    pairs.forEach((pair) => {
        const [key, value] = pair.split('=');

        // Decode the key and value to handle special characters
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value || '');

        queryParams.set(decodedKey, decodedValue);
    });

    return queryParams;
}

const checkTokenExpiration = (token: string) : string => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (!decodedToken.exp) {
        return "token doesn't have an expiration date"; // Token doesn't have an expiration date
      }

      // Check if the token is expired
      if (decodedToken.exp < currentTime) {
        return `token is expired at ${decodedToken.exp}`; // Token is expired
      } else {
        return ""; // Token is valid
      }

    } catch (error) {
      return `error decoding token: ${error}`;
    }
};