import React, { createContext, useState } from 'react';

export interface LogMessage {
    message: string;
    type: 'log' | 'warn' | 'error';
    time: Date;
}

// Define the types
interface LogContextType {
    logInfo: (message: string) => void;
    logWarn: (message: string) => void;
    logError: (message: string) => void;
    logs: LogMessage[];
}

// Create the context
export const LogContext = createContext<LogContextType>({
    logInfo: () => { },
    logWarn: () => { },
    logError: () => { },
    logs: []
});

interface Props {
    children: React.ReactNode;
}
  
// Create the provider
export const LogProvider: React.FC<Props> = ({ children }) => {
    const [logs, setLogs] = useState<LogMessage[]>([]);
  
    const logInfo = (message: string) => {
      console.log(message);

      const logMessage: LogMessage = {
        message,
        type: 'log',
        time: new Date()
      };
      setLogs((curr) =>[...curr, logMessage]);
    };
  
    const logWarn = (message: string) => {
      console.warn(message);

      const logMessage: LogMessage = {
        message: `[WARN] ${message}`,
        type: 'warn',
        time: new Date()
      };

      setLogs((curr) =>[...curr, logMessage]);
    };
  
    const logError = (message: string) => {
      console.error(message);

      const logMessage: LogMessage = {
        message: `[ERROR] ${message}`,
        type: 'error',
        time: new Date()
      };

      setLogs((curr) =>[...curr, logMessage]);
    };
  
    return (
      <LogContext.Provider value={{ logs, logInfo, logWarn, logError }}>
        {children}
      </LogContext.Provider>
    );
  };