import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { Formik } from "formik";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/CopyRight";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
//import logoImage from "../assets/images/tsglogo.png";
import { MenuItem, Select } from "@mui/material";
import { ParseQueryString, getEnvironment } from "../utils/apiService";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

export default function SignUp() {
  const navigate = useNavigate();
  const [confirmMode, setConfirmMode] = React.useState(false);
  const {
    signUp,
    isAuthenticated,
    isAuthenticating,
    unverifiedAccount,
    confirmAccount,
  } = useAuth();
  const [storefrontName, setStorefrontName] = React.useState("tsg");
  const [environment, setEnvironment] = React.useState("prod");
  //const [freeLeads, setFreeLeads] = React.useState(100);

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/signup']);
    _hsq.push(['trackPageView']);

    if (location.search.indexOf('confirm=true') >=0 ) {
      setConfirmMode(true)
    }

    const queryParams = ParseQueryString(location.search)
    let sf = queryParams.get("sf")

    if (sf == undefined || sf == "") {
      const savedStorefront = localStorage.getItem("storefront")
      if (savedStorefront) {
        sf = savedStorefront
      }

      if (sf == undefined || sf == "") {
        sf = "tsg"
      }
    }

    //if (sf == "kw") {
    //   setFreeLeads(200)
    // }

    setStorefrontName(sf)

    setEnvironment(getEnvironment())
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string().required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  //
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  if (isAuthenticating || isAuthenticated) {
    return <LoadingSpinner title="Signing Up..." textColor="#0352fc"/>;
  }

  const getLogo = () => {
    const colorMode = localStorage.getItem("colorMode")
    console.log("logo selection: colorMode=", colorMode, ", environment=", environment, ", storefront=", storefrontName)
    if (environment == "prod") {
      if (colorMode != undefined && colorMode == "dark") {
        return "https://shop.theshare.group/storefront/" + storefrontName + "_logo_dark.png"
      } else {
        return "https://shop.theshare.group/storefront/" + storefrontName + "_logo_light.png"
      }
    }

    if (colorMode != undefined && colorMode == "dark") {
      return "https://dev.portal.theshare.group/storefront/" + storefrontName + "_logo_dark.png"
    }

    return "https://dev.portal.theshare.group/storefront/" + storefrontName + "_logo_light.png"
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={getLogo()} width={128} height={48} />
        {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5" sx={{marginTop:2}}>
        Create a Free Account
        </Typography>
        <Typography sx={{marginTop:2}}>
        With a free account, you can search  all of our lead databases and find the perfect blend of lead types for your business.
        </Typography>
        {!confirmMode ? (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              phone: "",
              storename: "",
              submit: null,
            }}
            validationSchema={validationSchema}
            onSubmit={async (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              values: any,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              { setErrors, setStatus, setSubmitting }: any
            ): Promise<void> => {
              try {
                setSubmitting(true);
                // sign up user
                //const propName = "custom:storename"
                await signUp({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email.toLowerCase(),
                  password: values.password,
                  phone: values.phone,
                  storename: storefrontName,
                });
                setConfirmMode(true);
                setSubmitting(false);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (err: any) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }): JSX.Element => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={values?.firstName}
                      onChange={handleChange}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={values?.lastName}
                      onChange={handleChange}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      label="Phone"
                      name="phone"
                      value={values?.phone}
                      onChange={handleChange}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      autoComplete="phone"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      value={values?.password}
                      onChange={handleChange}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography>What business are you in?</Typography>
                      <Select id="hs_persona_business" name="hs_persona" label="">
                        <MenuItem value="">Please choose one</MenuItem>
                        <MenuItem value="persona_2_agent">Real estate agent</MenuItem>
                        <MenuItem value="persona_3_broker">Real estate broker</MenuItem>
                        <MenuItem value="persona_4_investor">Real Estate Investor</MenuItem>
                        <MenuItem value="persona_5_coach">Real Estate Coach</MenuItem>
                        <MenuItem value="persona_6_mort_pro">Real Estate Mortgage Professional</MenuItem>
                        <MenuItem value="persona_7_other">Other</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography>Target Geography</Typography>
                        <TextField
                        fullWidth
                        name="targetGeography"
                        label=""
                        id="targetGeography"
                        autoComplete="target-geography"
                      />
                    </Stack>
                  </Grid>
                </Grid>
                {/*Display Error with Icon*/}
                {errors?.submit && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ErrorOutlineIcon sx={{ color: "red", mr: 1 }} />
                    <Typography variant="body2" color="error">
                      {errors?.submit}
                    </Typography>
                  </Box>
                )}
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
              </Box>
            )}
          </Formik>
        ) : (
          <>
            <Divider sx={{ my: 2 }} />
            {/*Confirmation Code */}
            <Formik
              initialValues={{
                code: "",
                submit: null,
              }}
              validationSchema={Yup.object({
                code: Yup.string()
                  .min(6, "Confirmation Code should contain 6 characters")
                  .required("Code is required"),
              })}
              onSubmit={async (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                values: any,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                { setErrors, setStatus, setSubmitting }: any
              ): Promise<void> => {
                try {
                  setSubmitting(true);
                  // confirm sign up user
                  await confirmAccount({ code: values.code });
                  setSubmitting(false);
                  // navigate to dashboard
                  navigate("/", { replace: true });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (err: any) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }): JSX.Element => (
                <Grid
                  component="form"
                  onSubmit={handleSubmit}
                  container
                  spacing={2}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    item
                    xs={12}
                  >
                    <CheckCircleOutlineIcon sx={{ color: "green", mr: 1 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                    >
                      We have sent a confirmation code to your email address{" "}
                      <b>({unverifiedAccount?.email})</b>. Please enter the code
                      below to complete sign up. The email will be from 'info@theshare.group' and have a subject line containing thew words "Account Confirmation". If you do not receive an email then please check your junk mail folder.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="code"
                      label="Confirmation Code"
                      value={values?.code}
                      onChange={handleChange}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                      type="text"
                      id="code"
                      autoComplete="confirmationCode"
                    />
                    {/*Display Error with Icon*/}
                    {errors?.submit && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ErrorOutlineIcon sx={{ color: "red", mr: 1 }} />
                        <Typography variant="body2" color="error">
                          {errors?.submit}
                        </Typography>
                      </Box>
                    )}
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Confirm Sign Up
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </>
        )}
        {/* <Typography
          sx={{
            width: "100%",
            my: 2,
            textAlign: "center",
          }}
        >
          OR
        </Typography>
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "black",
            my: 2,
            ":hover": {
              backgroundColor: "whitesmoke",
            },
          }}
          startIcon={
            <Avatar src={googleImage} sx={{ width: 20, height: 20 }} />
          }
        >
          Sign Up with Google
        </Button>
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#1877F2",
            my: 2,
            ":hover": {
              backgroundColor: "#166FE5",
            },
          }}
          startIcon={<FacebookIcon fontSize="large" />}
        >
          Sign Up with Facebook
        </Button> */}
        <Grid container justifyContent="space-between">
          <Grid item>
            <Link href="/signin" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
          <Grid item>
            <Link href="/signupconfirmation" variant="body2">
              Resend Confirmation Code
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
