import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LeadsMap from "../components/LeadsMap/LeadsMap";
import * as React from "react";
import Drawer from '@mui/material/Drawer';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { CartItem, ShoppingCart, ShoppingCartResponse } from "../models/Store";
import ShoppingCartContainer from "../components/ShoppingCartContainer";
import {genUniqueId, generateOrderID} from "../utils/GenerateUniqueID";
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Storefront } from "../models/StorefrontResponse";
import { useLocation } from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import { APIEndpoint, EndpointType, httpPostNoAuth } from "../utils/apiService";
import { useDebounce } from "../hooks/useDebounce";
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "@mui/material/Snackbar";
import { useNavigate } from "react-router-dom";
import { CheckoutResponse } from "../models/CheckoutResponse";
import AddProductForm from "../components/AddProductForm";
import { ZipCodeCount } from "../models/ZipCodeCountsResponse";
import SEO from "../components/SEO";
import { Product } from "../models/Products";
import { useWalletBalance } from "../hooks/useWalletBalance";
import { useProductsAll } from "../hooks/useProductsAll";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { round } from "@turf/turf";
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLog } from "../hooks/useLogs";
import { useTSGUser } from "../contexts/TSGUserContext";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; 
const Product_Name_NoDNC = "Do-Not-Call Removal"
const Product_Name_PhonesEmails = "Missing Email Removal"
const Product_Name_EmailsNoDNC = "Do-Not-Call/Missing Email Removal"

interface Props {
  storefront: Storefront
}

const LeadsStore = (props: Props) => {
  const { logWarn, logInfo, logError } = useLog();
  const [showCart, setShowCart] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [minOrderAmount, setMinOrderAmount] = useState(200.0)
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [propertySelectionType, setPropertySelectionType] = useState("zipcode")
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [selectedZip, setSelectedZip] = useState("");
  const [radiusDistanceStr, setRadiusDistanceStr] = useState("1")
  const [radiusDistance, setRadiusDistance] = useState(1)
  const [radiusPoint, setRadiusPoint] = useState<number[]>([0,0])
  const [showGeographySelection, setShowGeographySelection] = useState(false)
  const [geographySelectionDisplayName, setGeographySelectionDisplayName] = useState("Geography: Zip Codes")
  const { tsgUser } = useTSGUser();
  const { walletBalance } = useWalletBalance(tsgUser?.id || "", 0)

  const [shoppingCart, setShoppingCart] = useState<ShoppingCart>({
    cartToken: genUniqueId(),
    createdAt: "",
    modifiedAt: "",
    hubspotDealID: "",
    subtotal: 0,
    total: 0,
    discount: 0,
    couponCode: "",
    items: [],
    status: "shopping",
    userID: "",
    storefrontID: props.storefront.storeName,
    discountDescription: "",
    orderID: generateOrderID(),
    applyWallet: true,
    walletCreditsApplied: 0
  })

  const [mapMargin, setMapMargin] = useState(0);
  const [cartCountDebounce, setCartCountDebounce] = useState(0)
  const debouncedCartCount = useDebounce(cartCountDebounce, 2000);
  // const { storefronts, storefrontLoading, storefrontError } = useStorefrontManager(storefrontName)
  const { isAuthenticated } = useAuth();
  const location = useLocation();  
  const navigate = useNavigate();
  // const { products } = useProducts(1)
  const { productsAll } = useProductsAll(props.storefront.storeName, 1)
  const [shouldIntervene, setShouldIntervene] = useState(false)
  const [zipCodeCount, setZipCodeCount] = useState<ZipCodeCount>();
  const [title, setTitle] = useState("")
  const [openOrderConfirmation, setOpenConfirmation] = React.useState(false);
  
  useEffect(() => {
    setMinOrderAmount(props.storefront.minOrderAmount)
  });

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/products' + location.search]);
    _hsq.push(['trackPageView']);
  }, []);

  useEffect(() => {
    if (zipCodeCount && zipCodeCount.zipCode) {
      setTitle("Real Estate Leads in " + zipCodeCount?.cityCap + ", " + zipCodeCount?.state + " " + zipCodeCount?.zipCode)
    } else {
      setTitle("")
    }
  }, [zipCodeCount]);

  useEffect(() => {
    logInfo("uploading shopping cart...")
    uploadShoppingCart(shoppingCart)
  }, [debouncedCartCount]);

  useEffect(() => {
    setPropertySelectionDisplayName()
  }, [propertySelectionType, radiusDistance]);

  useEffect(() => {
    if (shoppingCart) {
      logInfo("Cart Changed! Active Shopping Cart ID: " + shoppingCart.cartToken + ", orderID: " + shoppingCart.orderID + ", storefront: " + shoppingCart.storefrontID + ", wallet used: " + shoppingCart.applyWallet + ", Cart Status: " + shoppingCart.status)
      setShouldIntervene(shoppingCart.items.length > 0)
    } 
  }, [shoppingCart]);

  useEffect(() => {
    if (productsAll) {
      logInfo("Loaded " + productsAll.length + " products")
    } 
  }, [productsAll]);

  useEffect(() => {
    logInfo('Minimum Order Amount = $' + minOrderAmount)
  }, [minOrderAmount]);

  useEffect(() => {
    setShowGeographySelection(isAuthenticated)
  }, [isAuthenticated]);

  useEffect(() => {
    // Check for a cached shopping cart and retrieve it
    const cartStr = localStorage.getItem("cart")
    if (cartStr != null) {
      const cart:ShoppingCart = JSON.parse(cartStr)
      logInfo("Retrieved cached cart (" + cart.cartToken + "/" + cart.orderID + ")...Product count: " + cart.items.length)

      // breaking-change cart update on 12/2 requires old carts to be deleted and new ones created
      if (cart.items.length > 0 && cart.items[0].product == undefined) {
        logInfo("invalid cart item found...removing it.")
        cart.items = []
      }

      if (!cart.orderID.includes(dateToString(new Date()))) {
        const newOrderID = generateOrderID()
        logInfo(`old order ID found in cart...creating new orderID: ${newOrderID}`)
        cart.orderID = newOrderID
      }

      // if cart has no items then create a new cart
      if (cart.items.length == 0 && !cart.orderID.includes(dateToString(new Date()))) {
        logInfo("creating new cart since the cached one contains no items and is not from today")
        const newCart: ShoppingCart = {
          cartToken: genUniqueId(),
          createdAt: "",
          modifiedAt: "",
          hubspotDealID: "",
          subtotal: 0,
          total: 0,
          discount: 0,
          couponCode: "",
          items: [],
          status: "shopping",
          userID: tsgUser?.id || "",
          storefrontID: props.storefront.storeName,
          discountDescription: "",
          orderID: generateOrderID(),
          applyWallet: true,
          walletCreditsApplied: 0
        }
        setShoppingCart(newCart)
      } else {
        setShoppingCart(cart)
      }
    } else {
      // Create an empty cart
      const newCart: ShoppingCart = {
        cartToken: genUniqueId(),
        createdAt: "",
        modifiedAt: "",
        hubspotDealID: "",
        subtotal: 0,
        total: 0,
        discount: 0,
        couponCode: "",
        items: [],
        status: "shopping",
        userID: tsgUser?.id || "",
        storefrontID: props.storefront.storeName,
        discountDescription: "",
        orderID: generateOrderID(),
        applyWallet: true,
        walletCreditsApplied: 0,
      }
      setShoppingCart(newCart);
    }
  }, []);

  window.onbeforeunload = () => {
    if (shouldIntervene) {
      if (location.pathname === '/shop') {
        alert('do you want to leave?')
        return true;
      }
    }
  };

  useEffect(() => {
    const listener = () => true
    if (shouldIntervene) {
      window.addEventListener('beforeunload', listener)
    } else {
      window.removeEventListener('beforeunload', listener)
    }
  }, [shouldIntervene])

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  function getStorefrontName(): string {
    let storefrontName = props.storefront.storeName

    if (storefrontName == undefined || storefrontName == "") {
      const savedStorefront = localStorage.getItem("storefront")
      if (savedStorefront) {
        storefrontName = savedStorefront
      }
    }
      
    if (storefrontName == undefined || storefrontName == "") {
      logInfo("cannot determine storefront name...defaulting to 'tsg'")
      storefrontName = "tsg"
    }
    
    return storefrontName
  }

  const seoDescription = () : string => {
    if (zipCodeCount?.zipCode == undefined || zipCodeCount.zipCode == "") {
      return ""
    }
    return "#1 Source for Real Estate Seller Leads in zip code " + zipCodeCount?.zipCode + " | " + zipCodeCount?.cityCap + ", " + zipCodeCount?.state + " | 100% Owner Phone plus Email | Absentee Owners, Distressed Homeowners, Likely-to-Sell, Empty-Nest, Downsizer, Best Seller Leads for Real Estate Agents"
  }

  function calcCartSubtotal(cart: ShoppingCart): number {
    let price = 0
    
    for (const item of cart.items) {
      price += item.product.priceInfo.price * item.quantity
    }

    return price
  }

  function calcCartTotal(cart: ShoppingCart): number {
    let price = 0
    let leadBalance = 0

    if (cart.applyWallet) {
      if (cart.walletCreditsApplied) {
        leadBalance = cart.walletCreditsApplied
      }
    }

    for (const item of cart.items) {
      let quantity = item.quantity

      if (leadBalance > 0) {
        if (leadBalance >= quantity) {
          leadBalance -= quantity
          quantity = 0
        } else {
          quantity = quantity - leadBalance
          leadBalance = 0
        }
      }

      price += item.product.priceInfo.price * quantity
    }

    return price
  }

  // dateToString converts a date to string in the format of "YYYYMMDD"
  function dateToString(date_Object: Date): string {
    let month = (date_Object.getMonth() + 1) + ""
    if (month.length < 2) {
      month = "0" + month
    }

    let day = date_Object.getDate() + ""
    if (day.length < 2) {
      day = "0" + day
    }

    // get the year, month, date, hours, and minutes seprately and append to the string.
    return date_Object.getFullYear() + "" + month + day
  }

  const uploadShoppingCart = (cart: ShoppingCart) => {
    if (cart.items.length === 0 && cart.modifiedAt === '') {
      logInfo("Cart is new. Aborting save to cloud!")
      return
    }

    const apiURL =  APIEndpoint(EndpointType.StoreCart)
    httpPostNoAuth(apiURL, JSON.stringify(cart))
    .then((data) => {
        const shoppingCartResponse = data as ShoppingCartResponse;
        
        if (shoppingCartResponse.status == 'error') {
            logError("Error saving shopping cart: " + shoppingCartResponse.errorMessage)
        } else {
            logInfo("Shopping cart saved successfully!")

            // Save the cart to local storage so it can be retrieved later if the page is refreshed
            localStorage.setItem("cart", JSON.stringify(shoppingCartResponse.shoppingCart))

            // Update the in-memory shopping cart since there are values we need that were
            // passed-back from the server
            setShoppingCart(shoppingCartResponse.shoppingCart)
        }
    })
    .catch((error) => { 
        logError(`saveShoppingCart error: ${error as string}`)
    });    

  }

  const cartHasDNC = (cart: ShoppingCart) => {
    for (const item of cart.items) {
      if (item.product.features.noDNC) {
        return true
      }
    }
  }

  const cartHasEmails = (cart: ShoppingCart) => {
    for (const item of cart.items) {
      if (item.product.features.phonesEmails) {
        return true
      }
    }
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const findProduct = (productName: string): Product | null => {
    for (const prod of productsAll) {
      if (productName == prod.name) {
        return prod
      }
    }
    
    return null
  }

  const getCartProcessingItem = (cart: ShoppingCart): CartItem | null => {
    const cartDNC = cartHasDNC(cart)
    const cartEmails = cartHasEmails(cart)
    const cartItem: CartItem = {
      id: genUniqueId(),
      quantity: 1,
      postalCodes: [],
      product: {
        id: "",
        audienceID: 0,
        name: "",
        description: "",
        leadType: "",
        productType: "",
        price: 0,
        active: false,
        listCounts: [],
        stripeID: "",
        stripePrice: "",
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        detailedDescription: "",
        infoURL: "",
        outputFields: [],
        priceInfo: {
          price: 0,
          stripePriceID: ""
        },
        features: {
          noDNC: false,
          phonesEmails: false
        }
      }
    }

    if (cartDNC == undefined || cartEmails == undefined) {
      return null
    }

    if (!cartDNC && !cartHasEmails) {
      return null
    }

    if (cartDNC && cartEmails) {
      const prod = findProduct(Product_Name_EmailsNoDNC)
      if (prod != null) {
        cartItem.product = prod
        return cartItem
      }
    } else if (cartDNC) {
      const prod = findProduct(Product_Name_NoDNC)
      if (prod != null) {
        cartItem.product = prod
        return cartItem
      } 
    } else if (cartEmails) {
      const prod = findProduct(Product_Name_PhonesEmails)
      if (prod != null) {
        cartItem.product = prod
        return cartItem
      } 
    }

    logError("Failed to find a matching product! Could not set lead processing cart item. Did a product name change?")
    return null
  }

  const handleAddCarItemsToCart = (cartItems: CartItem[]) => {
    setShoppingCart((oldCart) => {
      const updatedCart = cartDeepCopy(oldCart) == null ? shoppingCart : oldCart;
      // let hasDNC = false
      // let hasEmails = false

      for (const cartItem of cartItems) {
        // Prevent the same product from being added twice
        for (const prod of updatedCart.items) {
          if (prod.postalCodes && cartItem.postalCodes && (cartItem.product.productType === 'leads' || cartItem.product.productType === 'investor_leads') && prod.postalCodes[0] == cartItem.postalCodes[0] && cartItem.product.name === prod.product.name) {
            logInfo("aborting addition of product...it already exists")
            return updatedCart
          }
        }
    
        if (isAuthenticated) {
          updatedCart.userID = tsgUser?.id || ""
          // if (updatedCart.userID == "" && user.username != undefined) {
          //   updatedCart.userID = user.username
          // }
        }
    
        updatedCart.items.push(cartItem);
        handleCloseProductForm()
      }
  
      // Configure any 'processing' products
      const processingCartItem = getCartProcessingItem(updatedCart)

      // Remove all 'processing' products for now. We will add it back shortly
      let i = updatedCart.items.length;
      while (i--) {
        if (updatedCart.items[i].product.productType == "processing") {
          updatedCart.items.splice(i, 1)
        }
      }

      let addProcessingItem = true

      // Apply wallet balance if applicable
      if (updatedCart.applyWallet) {
        const leadCount = calcLeadCount(updatedCart)
        
        if (walletBalance?.balance) {
          if (walletBalance.balance >= leadCount) {
            updatedCart.walletCreditsApplied = leadCount

            // Since the entire wallet is covering the price, we need to remove any processing products
            addProcessingItem = false
          } else {
            updatedCart.walletCreditsApplied = walletBalance.balance
          }
        } else {
          updatedCart.walletCreditsApplied = 0
        }
      } else {
        updatedCart.walletCreditsApplied = 0
      }

      // Add the processing item back if needed
      if (processingCartItem != null && addProcessingItem) {
        logInfo(`Adding Lead Processing to cart: ${processingCartItem}`)
        updatedCart.items.push(processingCartItem)
      }

      updatedCart.subtotal = calcCartSubtotal(updatedCart);
      updatedCart.total = calcCartTotal(updatedCart);

      // Get the total number of products and if a subscription get the number of zip codes
      // so that we can detect any change in the size of the shopping cart contents
      let shoppingCartSize = 0
      for (const product of updatedCart.items) {
        if (product.postalCodes) {
          shoppingCartSize += product.postalCodes.length
        } else if (product.radiusSettings) {
          shoppingCartSize += 1
        }
      }

      if (updatedCart.storefrontID == undefined || updatedCart.storefrontID == "") {
        updatedCart.storefrontID = getStorefrontName()
      }
      
      setCartCountDebounce(shoppingCartSize)
      showShoppingCart()
      //logCartContents(updatedCart)
      return updatedCart;
    })
  }

  function cartDeepCopy(cart: ShoppingCart): ShoppingCart {
    const newCart: ShoppingCart = {
      cartToken: cart.cartToken,
      createdAt: cart.createdAt,
      modifiedAt: cart.modifiedAt,
      hubspotDealID: cart.hubspotDealID,
      status: cart.status,
      userID: cart.userID,
      subtotal: cart.subtotal,
      total: cart.total,
      discount: cart.discount,
      couponCode: cart.couponCode,
      items: cart.items,
      storefrontID: cart.storefrontID,
      discountDescription: cart.discountDescription,
      orderID: cart.orderID,
      applyWallet: cart.applyWallet,
      walletCreditsApplied: cart.walletCreditsApplied
    }

    return newCart
  }

  const handleCloseShoppingCart = () => {
    setShowCart(false)
    setMapMargin(0)
  }

  const handleCloseProductForm = () => {
    setShowAddProductForm(false)
    setMapMargin(0)
  }

  const showShoppingCart = () => {
    setShowCart(true)
    handleCloseProductForm()
    setMapMargin(36)
  }

  const showProductForm = () => {
    setShowAddProductForm(true)
    setMapMargin(36)
  }

  // function logCartContents(cart: ShoppingCart) {
  //   console.logInfo("Cart Contents:")
  //   console.logInfo("  CartToken:      " + cart.cartToken)
  //   console.logInfo("  CreatedAt:      " + cart.createdAt)
  //   console.logInfo("  ModifiedAt:     " + cart.modifiedAt)
  //   console.logInfo("  HubSpotDealID:  " + cart.hubspotDealID)
  //   console.logInfo("  Status:         " + cart.status)
  //   console.logInfo("  UserID:         " + cart.userID)
  //   console.logInfo("  Subtotal:       " + cart.subtotal)
  //   console.logInfo("  Discount:       " + cart.discount)
  //   console.logInfo("  Total:          " + cart.total)
  //   console.logInfo("  Storefront:     " + cart.storefrontID)
  //   console.logInfo("  ApplyWallet:    " + cart.applyWallet)
  //   console.logInfo("  Wallet Credits: " + cart.walletCreditsApplied)
  //   console.logInfo("  Items: ")
  //   for (const item of cart.items) {
  //     console.logInfo("    ID:           " + item.product.id)
  //     console.logInfo("    Product Type: " + item.product.productType)
  //     console.logInfo("    Lead Type:    " + item.product.leadType)
  //     console.logInfo("    Zip Codes:    " + item.postalCodes)
  //     console.logInfo("    Quantity:     " + item.quantity)
  //     console.logInfo("    Price:        " + item.product.priceInfo.price)
  //     console.logInfo("    Name:         " + item.product.name)

  //     console.logInfo("    ----------------------------------")
  //   }
  // }

  const cartCount = () => {
    if (shoppingCart === undefined) {
      return 0
    }

    return shoppingCart.items.length
  }

  function calcLeadCount(cart: ShoppingCart) {
    if (cart === undefined) {
      return 0
    }

    let leadCount = 0
    for (const item of cart.items) {
      if (item.product == undefined) {
        continue
      }

      if (item.product.productType === 'leads' || item.product.productType === 'investor_leads') {
        leadCount += item.quantity
      }
    }

    return leadCount
  }

  useEffect(() => {
    if (selectedZip == "") {
      handleCloseProductForm()
    } else {
      showProductForm()
    }
    logInfo(`selectedZip: ${selectedZip}, ShowAddProductForm: ${showAddProductForm}`)
  }, [selectedZip]);

  const handleSelectedZipChanged = (zip: string) => {
    if (zip == "") {
      handleCloseProductForm()
    } else {
      showProductForm()
    }
    setSelectedZip(zip)
    const newRoute = "/products/" + zip

    navigate(newRoute)
  }

  const handleSelectedRadiusChanged = (lat: number, lon: number) => {
    logInfo(`Selected Radius Changed: ${lat}:${lon}`)
    setRadiusPoint([lon, lat])
    setSelectedZip("")
    showProductForm()
  }

  const applyWalletCredits = (apply: boolean) => {
    setShoppingCart((oldCart) => {
      const updatedCart = cartDeepCopy(oldCart) == null ? shoppingCart : oldCart;

      updatedCart.applyWallet = apply

      // Apply wallet balance if applicable
      if (updatedCart.applyWallet) {
        const leadCount = calcLeadCount(updatedCart)
        
        if (walletBalance?.balance) {
          if (walletBalance.balance >= leadCount) {
            updatedCart.walletCreditsApplied = leadCount

            // Since the entire wallet is covering the price, we need to remove any processing products
            let i = updatedCart.items.length;
            while (i--) {
              if (updatedCart.items[i].product.productType == "processing") {
                updatedCart.items.splice(i, 1)
              }
            }
          } else {
            updatedCart.walletCreditsApplied = walletBalance.balance
          }
        } else {
          updatedCart.walletCreditsApplied = 0
        }
      } else {
        updatedCart.walletCreditsApplied = 0
      }

      updatedCart.subtotal = calcCartSubtotal(updatedCart);
      updatedCart.total = calcCartTotal(updatedCart);

      setCartCountDebounce(cartCountDebounce + 67) // set to arbitrary number to trigger change
      //showShoppingCart()

      return updatedCart;
    })
  }

  const getWalletBalance = () => {
    if (walletBalance && walletBalance.balance) {
      return walletBalance.balance
    }

    return 0
  }

  const handleRemoveItemFromCart = (productID: string, productType: string, zip: string) => {
    if (shoppingCart === undefined) {
      return
    }

    const updatedCart = cartDeepCopy(shoppingCart)

    if (productType === 'leads' || productType === 'investor_leads') {
      updatedCart.items.splice(updatedCart.items.findIndex(item => item.id === productID), 1)
    } else {
      // Need to find the the zip code to remove
      // let newCount
      for (const iIdx in updatedCart.items) {
        if (updatedCart.items[iIdx].id === productID) {
            updatedCart.items[iIdx].postalCodes?.splice(updatedCart.items[iIdx].postalCodes?.findIndex(item => item === zip), 1)

            // If there are no more zips for this subscription then remove the product
            if (updatedCart.items[iIdx].postalCodes.length === 0) {
              updatedCart.items.splice(updatedCart.items.findIndex(item => item.id === productID), 1)
            }
          break
        } 
      }
    }

    // Configure any 'processing' products
    const processingCartItem = getCartProcessingItem(updatedCart)

    // Remove all 'processing' products for now. We will add it back shortly
    let i = updatedCart.items.length;
    while (i--) {
      if (updatedCart.items[i].product.productType == "processing") {
        updatedCart.items.splice(i, 1)
      }
    }

    let addProcessingItem = true

    // Apply wallet balance if applicable
    if (updatedCart.applyWallet) {
      const leadCount = calcLeadCount(updatedCart)
      
      if (walletBalance?.balance) {
        if (walletBalance.balance >= leadCount) {
          updatedCart.walletCreditsApplied = leadCount

          // Since the entire wallet is covering the price, we need to remove any processing products
          addProcessingItem = false
        } else {
          updatedCart.walletCreditsApplied = walletBalance.balance
        }
      } else {
        updatedCart.walletCreditsApplied = 0
      }
    } else {
      updatedCart.walletCreditsApplied = 0
    }

    // Add it back if needed
    if (processingCartItem != null && addProcessingItem) {
      updatedCart.items.push(processingCartItem)
    }

    updatedCart.subtotal = calcCartSubtotal(updatedCart);
    updatedCart.total = calcCartTotal(updatedCart);
    setShoppingCart(updatedCart)
    uploadShoppingCart(updatedCart)
  }

  // function emptyShoppingCart() {
  //   let newCart: ShoppingCart = {
  //     cartToken: genUniqueId(),
  //     createdAt: "",
  //     modifiedAt: "",
  //     hubspotDealID: "",
  //     subtotal: 0,
  //     total: 0,
  //     discount: 0,
  //     couponCode: "",
  //     items: [],
  //     status: "shopping",
  //     userID: "",
  //     storefrontID: props.storefront.storeName,
  //     discountDescription: "",
  //     orderID: generateOrderID(),
  //     applyWallet: true,
  //     walletCreditsApplied: 0
  //   }
  //   setShoppingCart(newCart);
  // }

  const zipCodeCountChanged = (zcc: ZipCodeCount) => {
    if (zcc == undefined) {
      return
    }

    setZipCodeCount(zcc)
  }

  const setPropertySelectionDisplayName = () => {
    logInfo(`propertySelectionType: ${propertySelectionType}`)
    if (propertySelectionType === 'zipcode') {
      setGeographySelectionDisplayName("Geography: Zip Codes")
    } else {
      setGeographySelectionDisplayName("Geography: Radius (" + radiusDistance + " miles)")
    }
  }

  const handleCheckoutClick = () => {
    if (shoppingCart.items.length === 0) {
      setSnackbarMsg("Cannot checkout! Shopping cart is empty")
      setOpenSnackbar(true)
      return
    }

    const leadCount = calcLeadCount(shoppingCart)
    if (shoppingCart.applyWallet && shoppingCart.walletCreditsApplied == leadCount) {
      logInfo(`LeadCount: ${leadCount}, Wallet Credits: ${shoppingCart.walletCreditsApplied}`, )
      // Prompt user to create order only if the wallet is covering the entire order
      // if (!window.confirm('Please confirm you would like to place this order')) {
      //     return
      // }
      setOpenConfirmation(true)
      return
    }

    continueCheckout()
  }

  const handleEmptyCartClick = () => {
    if (!window.confirm("Are you sure you want to empty the cart?")) {
      return
    }

    localStorage.removeItem("cart")
    // Create an empty cart
    const newCart: ShoppingCart = {
      cartToken: genUniqueId(),
      createdAt: "",
      modifiedAt: "",
      hubspotDealID: "",
      subtotal: 0,
      total: 0,
      discount: 0,
      couponCode: "",
      items: [],
      status: "shopping",
      userID: tsgUser?.id || "",
      storefrontID: props.storefront.storeName,
      discountDescription: "",
      orderID: generateOrderID(),
      applyWallet: true,
      walletCreditsApplied: 0,
    }
    setShoppingCart(newCart);
  }

  const continueCheckout = () => {
    const leadCount = calcLeadCount(shoppingCart)
    setShouldIntervene(false)
    const listener = () => true
    window.removeEventListener('beforeunload', listener)

    let foundOneTime = false
    for (const idx in shoppingCart.items) {
      if (shoppingCart.items[idx].product.productType === 'leads' || shoppingCart.items[idx].product.productType === 'investor_leads') {
        foundOneTime = true
      }

      // All orders must have a quantity of at least one
      if (shoppingCart.items[idx].quantity <= 0) {
        setSnackbarMsg("Cannot checkout! Please check your order. All products must have at least one lead ordered")
        setOpenSnackbar(true)
        return
      }
    } 

    if (foundOneTime && shoppingCart.total < minOrderAmount) {
      let showError = true
      // If the order is paid with the wallet (even a partial order) then there is no minimum
      if (shoppingCart.applyWallet && shoppingCart.walletCreditsApplied > 0) {
        showError = false
      }

      if (showError) {
        setSnackbarMsg("Cannot checkout! The minimum order amount has not been met")
        setOpenSnackbar(true)
        return
      }
    }

    if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
      shoppingCart.storefrontID = getStorefrontName()
    }
    
    if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
      logWarn("Cannot checkout with storefront! The storefront name could not be determined")
      setSnackbarMsg("Cannot checkout! The storefront name could not be determined. Please contact The Share Group with this message")
      setOpenSnackbar(true)
      return
    }

    if (isAuthenticated) {
      shoppingCart.userID = tsgUser?.id || ""
      // if (shoppingCart.userID == "" && user.username != undefined) {
      //   shoppingCart.userID = user.username
      // }
    }

    // If this is a split order where part is paid by wallet and the rest with Stripe then
    // let user know we are adjusting quantities.
    if (shoppingCart.applyWallet && shoppingCart.walletCreditsApplied > 0 && shoppingCart.walletCreditsApplied < leadCount) {
      if (!window.confirm('FYI: This order is partially being paid via your wallet. The quantity of leads ordered will be adjusted during checkout to reflect only the balance remaining to be paid.')) {
          return
      }
    }

    const apiURL =  APIEndpoint(EndpointType.Checkout)
    httpPostNoAuth(apiURL, JSON.stringify(shoppingCart))
    .then((data) => {
        const checkoutResponse = data as CheckoutResponse;
        
        if (checkoutResponse.status == 'error') {
            logError("Error checking-out: " + checkoutResponse.errorMessage)
            setSnackbarMsg("Error Checking out. " + checkoutResponse.errorMessage)
            setOpenSnackbar(true)
        } else {
            // Redirect to the checkout page
            if (shoppingCart.applyWallet && shoppingCart.walletCreditsApplied == leadCount) {
              localStorage.removeItem("cart")
              // Create an empty cart
              const newCart: ShoppingCart = {
                cartToken: genUniqueId(),
                createdAt: "",
                modifiedAt: "",
                hubspotDealID: "",
                subtotal: 0,
                total: 0,
                discount: 0,
                couponCode: "",
                items: [],
                status: "shopping",
                userID: tsgUser?.id || "",
                storefrontID: props.storefront.storeName,
                discountDescription: "",
                orderID: generateOrderID(),
                applyWallet: true,
                walletCreditsApplied: 0,
              }
              setShoppingCart(newCart);
              navigate("/checkoutsuccess")
            } else {
              window.location.replace(checkoutResponse.redirectURL);
            }
        }
    })
    .catch((error) => { 
        logError("saveShoppingCart error: " + error.message)
    });    
  }

  const handleOpenPropertySelectionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClosePropertySelectionMenu = () => {
    setAnchorElUser(null);
  };

  const onZipcodePropertySelectionChanged = () => {
    setAnchorElUser(null);
    setRadiusPoint([])
    setPropertySelectionType("zipcode")
  };

  const onRadiusPropertySelectionChanged = () => {
    setPropertySelectionType("radius")
  };

  const onRadiusDistanceChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setRadiusDistanceStr(event.target.value)
  }

  const onSetRadiusClick = () => {
    let radius = parseFloat(radiusDistanceStr)

    if (radius != undefined) {
      radius = round(radius, 1)

      if (radius > 99) {
        setSnackbarMsg("Radius distance must be between .1 and 99 miles")
        setOpenSnackbar(true)
        return
      }
      setRadiusDistance(radius)
      setAnchorElUser(null);
    }
  }

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
  };

  return (
    <SEO title={title} description={seoDescription()} name="The Share Group" type="website" >
    <Box sx={{marginTop:0, backgroundColor: props.storefront?.activeColors.mainPage.pageMapBorderBackground}}>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{minHeight:'100vh'}}>
          <Paper sx={{ p: 1, display: "flex", flexDirection: "column", marginRight:mapMargin, backgroundColor: props.storefront?.activeColors.mainPage.pageMapBorderBackground}}>
            <Stack>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{marginRight:-1.75}}>
                { showGeographySelection && 
                <Box sx={{}}>
                  <Tooltip title="Change Geography Selection Mode">
                    <Button
                      size="medium"
                      onClick={handleOpenPropertySelectionMenu}
                      sx={{ width:300 }}
                    >
                      <Chip sx={{marginTop:1}} label={geographySelectionDisplayName} icon={<ExpandMoreIcon />}/>
                    </Button>
                  </Tooltip>
                  <Paper sx={{width: 320, maxWidth:'100%'}}>
                    <Menu
                      sx={{ mt: "41px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleClosePropertySelectionMenu}
                    >
                      <MenuItem key="zipcodeMode" onClick={onZipcodePropertySelectionChanged}>
                        <ListItemIcon>
                          {propertySelectionType == "zipcode" && <CheckIcon />}
                        </ListItemIcon>
                        Zipcodes
                      </MenuItem>
                      <MenuItem key="radiusMode" onClick={onRadiusPropertySelectionChanged}>
                        <Stack direction="row">
                          <ListItemIcon>
                          {propertySelectionType == "radius" && <CheckIcon />}
                          </ListItemIcon>
                          <Box>
                            <Typography>Radius (miles)</Typography>
                            {propertySelectionType == "radius" &&
                              <Box>
                                <Typography variant="body2">Distance:</Typography>
                                <Stack direction="row" spacing={1}>
                                  <Tooltip title="Radius Distance (.1 - 99 miles)">
                                    <TextField sx={{width:80}} onChange={onRadiusDistanceChanged} value={radiusDistanceStr}></TextField>
                                  </Tooltip>
                                  <Button variant="contained" size="small" onClick={onSetRadiusClick}>Set</Button>
                                </Stack>
                              </Box>
                            }
                          </Box>
                        </Stack>
                      </MenuItem>
                    </Menu>
                  </Paper>  
                </Box>
                }
                {/* <Box sx={{marginTop:0, marginRight:0}}/> */}
                <Box alignItems="flex-start" justifyContent="space-between" sx={{marginRight:1, marginTop:0}}>
                  <IconButton onClick={showShoppingCart} sx={{color: props.storefront?.activeColors.mainPage.pageIconBackground, marginRight:1}}>
                    <StyledBadge badgeContent={cartCount() + ''} color="secondary">
                      <ShoppingCartIcon />
                    </StyledBadge>
                  </IconButton>
                </Box>
              </Stack>
              <LeadsMap 
                storefront={props.storefront} 
                selectedZipChangedCallback={handleSelectedZipChanged} 
                selectedRadiusChangedCallback={handleSelectedRadiusChanged}
                uiMode={propertySelectionType}
                radiusDistance={radiusDistance}
                zipCodeCountChangedCallback={(zcc: ZipCodeCount) => {zipCodeCountChanged(zcc)}}
              />
            </Stack>
          </Paper>
          <Drawer open={showCart} variant="persistent" anchor="right">
            <Box sx={{width: 290, backgroundColor: props.storefront?.activeColors.cart.cartBackground, height:'100%'}}>
              {shoppingCart
                ? <ShoppingCartContainer 
                    storefront={props.storefront} 
                    cart={shoppingCart} 
                    minOrderAmount={minOrderAmount}
                    walletBalance={getWalletBalance()}
                    removeItemCallback={handleRemoveItemFromCart} 
                    closeCartCallback={handleCloseShoppingCart} 
                    applyWalletCredits={applyWalletCredits}
                    checkoutCallback={handleCheckoutClick}
                    emptyCartCallback={handleEmptyCartClick} />
                : <Box></Box>
              }
            </Box>
          </Drawer>
          <Drawer open={showAddProductForm} variant="persistent" anchor="right">
            <Box sx={{width: 290, backgroundColor: props.storefront?.activeColors.addProductForm.formBackground, height:'100%'}}>
              <AddProductForm 
                zip={selectedZip} 
                closeAddProductForm={handleCloseProductForm} 
                products={productsAll} 
                selectionMode={propertySelectionType}
                radiusDistance={radiusDistance}
                radiusPoint={radiusPoint}
                storefront={props.storefront} 
                addProducts={handleAddCarItemsToCart}
                zipCodeCountChangedCallback={(zcc: ZipCodeCount) => {zipCodeCountChanged(zcc)}}
              />
            </Box>
          </Drawer>
        </Grid>
      </Grid>
      <Dialog
        open={openOrderConfirmation}
        keepMounted
        onClose={handleConfirmationClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Place Order?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please confirm you would like to place this order. Your wallet will be charged for the full amount.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose}>Cancel</Button>
          <Button onClick={continueCheckout}>Place Order</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMsg}
          action={snackbarAction}
        />
      {/* <Footer position="relative" storefrontName={storefront.storeName} /> */}
    {/* // </Container> */}
    {/* <Helmet>
      <title>{title}</title>
      <meta name="description" content="#1 Source for Real Estate Seller Leads in zip  | SICKLERVILLE, NJ | 100% Owner Phone plus Email | Absentee Owners, Distressed Homeowners, Likely-to-Sell, Empty-Nest, Downsizer, Best Seller Leads for Real Estate Agents" />

    </Helmet> */}
    </Box>
    </SEO>
  );
};

export default LeadsStore;
