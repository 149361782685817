import { useEffect, useState } from 'react';
import { APIEndpoint, EndpointType, httpPost } from "../utils/apiService";
import { SuppressionList, SuppressionListResponse } from '../models/Suppression';

export const useSuppressionAnalysis = (suppressionList: SuppressionList | undefined, refreshKey: number) => {
    const [suppressionAnalysis, setSuppressionAnalysis] = useState<SuppressionList>()
    const [suppressionAnalysisError, setSuppressionAnalysisError] = useState("")
    const [suppressionAnalysisLoading, setSuppressionAnalysisLoading] = useState(false)
    const apiURL =  APIEndpoint(EndpointType.Suppressions);

    useEffect(() => {
        if (!suppressionList || suppressionList.s3Uri == "" || refreshKey == 0) {
            return
        }

        console.log("Running Suppression Analysis...")
        setSuppressionAnalysis({} as SuppressionList)
        setSuppressionAnalysisError("")
        setSuppressionAnalysisLoading(true)

        httpPost(apiURL, JSON.stringify(suppressionList))
            .then((data) => {
                const suppressionListResponse = data as SuppressionListResponse;
                if (suppressionListResponse.status === 'error') {
                    setSuppressionAnalysisError(suppressionListResponse.errorMessage)
                } else {
                    if (suppressionListResponse.suppressions.length > 0) {
                        console.log("Suppression Analysis: ", suppressionListResponse.suppressions[0])
                        setSuppressionAnalysis(suppressionListResponse.suppressions[0])
                    }
                }
            })
            .catch((error) => setSuppressionAnalysisError(error))
            .finally(() => setSuppressionAnalysisLoading(false))
    }, [suppressionList]);

    return { suppressionAnalysis, suppressionAnalysisLoading, suppressionAnalysisError };
}