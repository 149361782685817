import * as React from 'react';
import { Alert, Box, Button, Container, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, linearProgressClasses, MenuItem, Paper, Select, SelectChangeEvent, Stack, styled, Switch, TextField, Tooltip, Typography } from '@mui/material';
import Title from '../components/Title';
import { useEffect, useState } from 'react';
import { Storefront } from '../models/StorefrontResponse';
import { ComponentBlock } from '../components/component-block';
import RadiusMap from '../components/RadiusMap';
import { useBlueprintCounts } from '../hooks/useBlueprintCounts';
import { Blueprint, BlueprintAccess, BlueprintResponse, QueryBlueprint, QueryFilter } from '../models/Blueprint';
import { generateOrderID, genUniqueId } from '../utils/GenerateUniqueID';
import { useWalletBalance } from '../hooks/useWalletBalance';
import { APIEndpoint, EndpointType, getEnvironment, httpPost, httpPostNoAuth } from '../utils/apiService';
import { CartItem, ShoppingCart } from '../models/Store';
import useAuth from '../hooks/useAuth';
import { CheckoutResponse } from '../models/CheckoutResponse';
import { useNavigate } from 'react-router-dom';
import { useSuppressions } from '../hooks/useSuppressions';
import { useTSGUser } from '../contexts/TSGUserContext';

interface Props {
  storefront: Storefront,
}

interface Search {
  iteration: number,
  minRadius: number,
  maxRadius: number,
  count: number
  radius: number
}

export default function RadiusSearch(props: Props) {
  const [dataSource, setDatasource] = useState("29");
  const [zipCode, setZipCode] = useState("");
  const [searchZip, setSearchZip] = useState("");
  const [quantity, setQuantity] = useState(1000);
  const [radiusDistance, setRadiusDistance] = useState(1);
  const [customerID, setCustomerID] = useState("");
  const [blueprintCountRequest, setBlueprintCountRequest] = React.useState<QueryBlueprint>();
  const [suppressOrders, setSuppressOrders] = useState(true);
  const [buildQueryWarningMsg, setBuildQueryWarningMsg] = useState("");
  const [requirePhones, setRequirePhones] = useState(false);
  const [filterDNC, setFilterDNC] = useState(false);
  const [iterations, setIterations] = useState(0);
  const [maxCountRadius, setMaxCountRadius] = useState(0);
  const [minCountRadius, setMinCountRadius] = useState(0);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searches, setSearches] = useState<Search[]>([]);
  const [searchResult, setSearchResult] = useState<Search>();
  const [savedBlueprint, setSavedBlueprint] = React.useState<Blueprint>()
  const [suppressionFile, setSuppressionFile] = useState("none");

  const maxIterations = 10
  const consumer13OutputFieldWhiteList: string[] = ["first", "middle", "last", "address", "city", "state", "zip", "zip4", "phone", "phone_type", "phone_2", "phone_type_2", "email", "dnc", "dnc_2", "latitude", "longitude", "exact_age", "home_value", "lor", "year_built", "presence_of_children", "marital_status", "est_income", "loan_to_value", "most_recent_mortgage_date", "most_recent_mortgage_amount", "education", "property_type", "home_purchase_date", "purchase_price", "occupation", "language", "number_of_children"];
  const consumerMortgage31OutputFieldWhitelist: string[] = ["first", "middle", "last", "address", "city", "state", "zip", "phone", "phone_type", "phone_2", "phone_type_2", "dnc", "dnc_2", "email", "land_use", "secondary_address", "secondary_city", "secondary_state", "secondary_zip", "situs_latitude", "situs_longitude", "sale_price", "sale_date", "stories_number", "bedrooms", "total_baths", "living_square_feet", "year_built", "loan_to_value", "most_recent_mortgage_date", "most_recent_mortgage_amount"]
  const timeshare29OutputFieldWhiteList: string[] = ["phone", "phone_type", "first", "last", "address1", "city", "state", "zip"]

  // Hooks
  const { tsgUser } = useTSGUser();
  const { blueprintCount, blueprintCountError } = useBlueprintCounts(blueprintCountRequest, suppressOrders, customerID, suppressionFile)
  const { suppressions } = useSuppressions(customerID, 0)
  const { walletBalance } = useWalletBalance(customerID, 1)
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setSuppressOrders(true);
  }, []);

  useEffect(() => {
    setCustomerID(tsgUser?.id || "")
  }, [tsgUser]);

  useEffect(() => {
    if (savedBlueprint && savedBlueprint?.id != "") {
      console.log("savedBlueprint:", savedBlueprint)
      submitOrder()
    }
  }, [savedBlueprint]);

  useEffect(() => {
    if (!activeSearch) {
      console.log("Search Cancelled")
      return
    }

    if (blueprintCount) {
      const searchResult: Search = {
        iteration: iterations,
        minRadius: minCountRadius,
        maxRadius: maxCountRadius,
        count: blueprintCount,
        radius: radiusDistance
      }
      const newSearches = searches
      newSearches.push(searchResult)

      setSearches([...searches, searchResult])
      console.log("Searches: ", searches)

      console.log(`Search Result: Count: ${blueprintCount}, Radius: ${radiusDistance}`)
      if (isCountWithinQuantityRange(blueprintCount)) {
        console.log(`Count is within range...${blueprintCount} records found within ${radiusDistance} miles of zip code ${quantity}`)
        setActiveSearch(false)
        showResults(newSearches)
        return
      } else {
        let minRadius = minCountRadius
        let maxRadius = maxCountRadius
        if (iterations >= maxIterations) {
          setActiveSearch(false)
          showResults(newSearches)
          return
        } else {

          console.log("setting min/max radius...blueprintCount: ", blueprintCount, "quantity: ", quantity, "radiusDistance: ", radiusDistance)
          if (blueprintCount > quantity) {
            if (maxCountRadius == 0 || radiusDistance < maxCountRadius) {
              const prevMaxCountRadius = maxCountRadius
              maxRadius = radiusDistance
              setMaxCountRadius(radiusDistance)
              console.log(`Setting MaxCountRadius from ${prevMaxCountRadius} to ${radiusDistance}`)
            }
          } else {
            if (radiusDistance > minCountRadius) {
              const prevMinCountRadius = minCountRadius
              minRadius = radiusDistance
              setMinCountRadius(radiusDistance)
              console.log(`Setting MinCountRadius from ${prevMinCountRadius} to ${radiusDistance}`)
            }
          }
        }

        setIterations(iterations + 1)
        binarySearchRadiusSelection(minRadius, maxRadius)
      }
    }
  }, [blueprintCount]);

  const getStripeCustomProductID = (): string => {
    if (getEnvironment() == "dev") {
      return "prod_Pv636v3I3I6hgr"
    }

    return "prod_Pv65QabwUkOvYl"
  }

  function getLeadStripePriceID(): string {
    if (getEnvironment() == "dev") {
      return "price_1PVJvkCxNi5DvLVrvxMwE0zk"
    } else {
      return "price_1PVK4gCxNi5DvLVrL090ac26"
    }
  }

  const submitOrder = () => {
    if (!savedBlueprint) {
      return
    }

    const shoppingCart: ShoppingCart = {
      cartToken: genUniqueId(),
      createdAt: "",
      modifiedAt: "",
      hubspotDealID: "",
      subtotal: calcSubtotal(),
      total: calcTotal(),
      discount: 0,
      couponCode: "",
      items: [],
      status: "shopping",
      userID: tsgUser?.id || "",
      storefrontID: props.storefront.storeName,
      discountDescription: "",
      orderID: generateOrderID(),
      applyWallet: appliedWalletCredits() > 0,
      walletCreditsApplied: appliedWalletCredits()
    }

    const cartItem: CartItem = {
      id: genUniqueId(),
      quantity: getPurchaseCount(),
      postalCodes: [],
      product: {
        audienceID: 0,
        description: "Radius Search Leads",
        detailedDescription: savedBlueprint.name,
        id: savedBlueprint.id,
        name: "Radius Search Leads",
        price: .2,
        productType: "custom_leads",
        listCounts: [],
        active: true,
        stripeID: getStripeCustomProductID(),
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        infoURL: "",
        priceInfo: {
          price: .2,
          stripePriceID: getLeadStripePriceID()
        },
        outputFields: [],
        features: {
          noDNC: false,
          phonesEmails: false,
        },
        stripePrice: getLeadStripePriceID(),
        leadType: "",
      }
    }

    shoppingCart.items.push(cartItem)

    if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
      shoppingCart.storefrontID = props.storefront.storeName
    }

    if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
      console.log("Cannot checkout with storefront! The storefront name could not be determined")
      setBuildQueryWarningMsg("Cannot checkout with storefront! The storefront name could not be determined. Are you logged-in?")
      return
    }

    if (isAuthenticated) {
      shoppingCart.userID = tsgUser?.id || ""
    } else {
      console.log("Cannot checkout without user! The user could not be determined")
      setBuildQueryWarningMsg("Cannot checkout at this time! The user could not be determined. Are you logged-in?")
      return
    }

    const apiURL = APIEndpoint(EndpointType.Checkout)
    httpPostNoAuth(apiURL, JSON.stringify(shoppingCart))
      .then((data) => {
        const checkoutResponse = data as CheckoutResponse;

        if (checkoutResponse.status == 'error') {
          console.log("Error checking-out: " + checkoutResponse.errorMessage)
          setBuildQueryWarningMsg("Error Checking out. " + checkoutResponse.errorMessage)
        } else {
          if (appliedWalletCredits() >= getPurchaseCount()) {
            // The wallet handled everything so just redirect to the orders page
            navigate("/myorders")
            return
          }
          console.log("Checkout session created successfuly...redirecting to checkout page")

          // Redirect to the checkout page
          window.location.replace(checkoutResponse.redirectURL);

        }
      })
      .catch((error) => {
        console.log("saveShoppingCart: " + error.message)
      });
  }

  const binarySearchRadiusSelection = (minRadius: number, maxRadius: number) => {
    let newRadius = 0
    if (maxRadius > 0 && minRadius == 0) {
      newRadius = radiusDistance * .5
    } else if (maxRadius == 0 && minRadius > 0) {
      newRadius = radiusDistance * 1.5
    } else {
      newRadius = maxRadius - ((maxRadius - minRadius) / 2)
    }

    console.log(`Setting new radius to ${newRadius}`)
    setRadiusDistance(newRadius)
  }

  const showResults = (searchResults: Search[]) => {
    // If the last search is within 5% of the desired quantity, then display it
    if (searchResults.length > 0) {
      const lastSearch = searchResults[searchResults.length - 1]
      if (isCountWithinQuantityRange(lastSearch.count)) {
        setSearchResult(lastSearch)
      } else {
        // Loop through each search and find the the lowest count that is more than the desired quantity
        let result: Search = { iteration: 0, minRadius: 0, maxRadius: 0, count: 0, radius: 0 }
        for (let i = 0; i < searchResults.length; i++) {
          if (searchResults[i].count > quantity) {
            if (searchResults[i].count < result.count || result.count == 0) {
              result = searchResults[i]
            }
          }
        }
        if (result.count > 0) {
          setSearchResult(result)
        } else {
          setBuildQueryWarningMsg("Unfortunately we couldn't find the desired quantity of leads. Please adjust search settings and try again.")
        }
      }
    }
  }

  function isCountWithinQuantityRange(count: number): boolean {

    // If the count is within 5% of the desired quantity, then we're good
    if (count >= quantity * 0.95 && count <= quantity * 1.05) {
      return true
    }

    return false
  }

  const handleDatasourceChange = (event: SelectChangeEvent) => {
    setDatasource(event.target.value as string);
  }

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(event.target.value);

    // If the zip code is 5 digits, then we can search
    if (event.target.value.length == 5) {
      setSearchZip(event.target.value);
    } else {
      setSearchZip("");
    }
  }

  const handleSearchButtonClicked = () => {
    console.log("********** Starting Search **********")
    setSearches([])
    setSearchResult(undefined)
    setBuildQueryWarningMsg("")
    setMaxCountRadius(0)
    setMinCountRadius(0)
    setIterations(1)
    setActiveSearch(true)
  }

  // Since we cannot begin a search until all states are initialized, we use this to wait for them
  useEffect(() => {
    if (activeSearch && minCountRadius == 0 && maxCountRadius == 0 && iterations == 1 && searches.length == 0 && searchZip != "") {
      setStartRadius()
    }
  }, [radiusDistance, maxCountRadius, minCountRadius, iterations, activeSearch, searches]);

  useEffect(() => {
    if (radiusDistance > 0 && activeSearch) {
      runSearch()
    }
  }, [radiusDistance]);

  const handleCancelButtonClicked = () => {
    setActiveSearch(false)
  }

  const getOutputFields = (): string[] => {
    switch (dataSource) {
      case "13":
        return consumer13OutputFieldWhiteList
      case "31":
        return consumerMortgage31OutputFieldWhitelist
      case "29":
        return timeshare29OutputFieldWhiteList
      default:
        return []
    }
  }

  const setStartRadius = () => {
    let startRadius = 16
    if (quantity < 1000) {
      startRadius = 2
    } else if (quantity < 5000) {
      startRadius = 4
    } else if (quantity < 10000) {
      startRadius = 8
    }
    console.log("Setting start radius to ", startRadius)
    setRadiusDistance(startRadius)
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(event.target.value));
  }

  const requirePhonesChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setRequirePhones(checked)
  }

  const filterDNCChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilterDNC(checked)
  }

  const runSearch = () => {
    const newBlueprint = assembleBlueprint()
    if (newBlueprint == undefined) {
      return
    }

    console.log(`Iteration #${iterations} MinCountRadius: ${minCountRadius}, MaxCountRadius: ${maxCountRadius}, RadiusDistance: ${radiusDistance}`)

    setBlueprintCountRequest(newBlueprint)
  }

  const assembleBlueprint = (): QueryBlueprint | undefined => {
    setBuildQueryWarningMsg("")
    const filters = assembleFilters()

    if (filters.length == 0) {
      return undefined
    }

    const dataSourceInt: number = +dataSource
    const newBlueprint: QueryBlueprint = {
      name: tsgUser?.lastName + "_" + genUniqueId() + "_radiussearch",
      data_source_id: dataSourceInt,
      match_type: "phone",
      filters: assembleFilters(),
      dedupe_within: "",
      output_fields: [],
      select_type: "address_zip",
      phone_type: "wireless_priority",
      dnc: filterDNC,
    }

    if (requirePhones) {
      newBlueprint.phones = "all"
    }

    console.log("Blueprint:", newBlueprint)
    setBlueprintCountRequest(newBlueprint)
    return newBlueprint
  }

  const assembleFilters = (): QueryFilter[] => {
    const filters: QueryFilter[] = []

    if (radiusDistance <= 0) {
      setBuildQueryWarningMsg("The search radius is invalid")
      return []
    }

    if (searchZip == "") {
      setBuildQueryWarningMsg("A zip code must be specified")
      return []
    } else {
      const newFilter: QueryFilter = { field: "zip_radius", condition: "geography", values: [`${searchZip}-${radiusDistance}`], omit: false }
      filters.push(newFilter)
    }

    if (dataSource == "29") {
      const newFilter: QueryFilter = { field: "dpv_indicator", condition: "and", values: ["Y"], omit: false }
      filters.push(newFilter)
    }

    return filters
  }

  const getPurchaseCount = (): number => {
    if (searchResult && searchResult.count > 0) {
      if (searchResult.count > quantity) {
        return quantity
      } else {
        return searchResult.count
      }
    }
    return 0
  }

  const buyNowWithWalletButtonClicked = () => {
    saveBlueprint()
  }

  const handleSuppressionFileChange = (event: SelectChangeEvent) => {
    const id = event.target.value as string;
    setSuppressionFile(id as string);
    console.log("Selected Suppression File: ", id)
  }

  const createBlueprintName = (): string => {
    return `${tsgUser?.lastName} - ${getPurchaseCount()} leads - ${searchZip} - ${Math.round((radiusDistance + Number.EPSILON) * 100) / 100} miles`
  }

  const saveBlueprint = () => {
    const bpAccess: BlueprintAccess = {
      scope: "user",
      value: tsgUser?.id || ""
    }

    const bprint: Blueprint = {
      id: "",
      name: createBlueprintName(),
      queryBlueprint: blueprintCountRequest || { name: "", data_source_id: 0, match_type: "", filters: [], dedupe_within: "", output_fields: [], select_type: "", phone_type: "", dnc: false },
      createdBy: "",
      createdAt: "",
      modifiedAt: "",
      access: bpAccess
    }

    bprint.queryBlueprint.output_fields = getOutputFields()

    const apiURL = APIEndpoint(EndpointType.Blueprints)
    httpPost(apiURL, JSON.stringify(bprint))
      .then((data) => {
        const response = data as BlueprintResponse;

        if (response.status == 'error') {
          console.log("Error saving blueprint: " + response.errorMessage)
          setBuildQueryWarningMsg("Failed to save Blueprint..." + response.errorMessage || "Unknown error")
        } else {
          console.log("Blueprint saved successfully!")
          setSavedBlueprint(response.blueprint)
        }
      })
      .catch((error) => {
        setBuildQueryWarningMsg("An error occurred while saving the Blueprint. Please try again." + error.message)
      });
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const calcSubtotal = (): number => {
    return getPurchaseCount() * .2
  }

  const appliedWalletCredits = (): number => {
    let wallFunds = walletBalance?.balance || 0

    const purchCount = getPurchaseCount()

    if (wallFunds >= purchCount) {
      wallFunds = purchCount
    } else {
      wallFunds = walletBalance?.balance || 0
    }

    return wallFunds
  }

  const calcTotal = (): number => {
    return (getPurchaseCount() - appliedWalletCredits()) * .2
  }

  const calcPotentialWalletSavings = (): number => {
    const purchCount = getPurchaseCount()

    if (purchCount < 2500) {
      return 0
    }

    if (purchCount < 5000) {
      return calcTotal() - (purchCount * .18)
    }

    if (purchCount < 10000) {
      return calcTotal() - (purchCount * .16)
    }

    if (purchCount >= 10000) {
      return calcTotal() - (purchCount * .14)
    }

    return 0
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
      ...theme.applyStyles('dark', {
        backgroundColor: '#308fe8',
      }),
    },
  }));


  return (
    <Container maxWidth="xl" disableGutters={true} sx={{ mt: 2 }}>
      <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
        <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Title>Radius Search</Title>

            <Stack spacing={2} sx={{}}>
              <Typography variant="body1" component="div">You specify the Zip Code and how many leads you want and we'll find the right geographical radius to get them. In order to dial-in the the closest leads to your geography, we conduct a variety of searches. Once you click 'Search', sit back, relax and watch the analysis take place.</Typography>
              <ComponentBlock title="Search Settings" storefront={props.storefront} sx={{ ml: 1 }}>
                <FormControl fullWidth size="small" sx={{ width: 300 }}>
                  <Tooltip title="Select which data source to select records from" placement="top">
                    <InputLabel id="datasource-label">Data Source</InputLabel>
                  </Tooltip>
                  <Select labelId="datasource" label="Data Source" onChange={handleDatasourceChange} defaultValue="" size="medium" value={dataSource}>
                    <MenuItem value="13">Consumer</MenuItem>
                    <MenuItem value="31" key="31">Consumer Mortgage</MenuItem>
                    <MenuItem value="29" key="29">Timeshare</MenuItem>
                  </Select>
                </FormControl>

                <Tooltip title="Specify one zip code to be used as the central point for the search" placement="top">
                  <TextField fullWidth size="medium" sx={{ width: 150 }} label="Zip Code" variant="outlined" required value={zipCode} onChange={handleZipCodeChange} />
                </Tooltip>

                <Tooltip title="Specify the number of leads you want to purchase" placement="top">
                  <TextField fullWidth size="medium" sx={{ width: 150 }} label="Desired Quantity" variant="outlined" required value={quantity} onChange={handleQuantityChange} />
                </Tooltip>

                <Tooltip title="Require phone numbers on all records" placement="top">
                  <FormControlLabel control={<Switch checked={requirePhones} onChange={requirePhonesChanged} sx={{ ml: 4 }} />} label="Require Phones" />
                </Tooltip>

                <Tooltip title="Filter out records on the National Do Not Call list" placement="top">
                  <FormControlLabel control={<Switch checked={filterDNC} onChange={filterDNCChanged} sx={{ ml: 4 }} />} label="Remove DNC Phones" />
                </Tooltip>

                <FormControl fullWidth size="small" sx={{ width: 300 }}>
                  <Tooltip title="Select a suppression list. You can upload a suppression file by clicking on avatar icon in the upper-right and selecting 'Suppression Files'" placement="top">
                    <InputLabel id="suppressions-label">Suppression File</InputLabel>
                  </Tooltip>
                  <Select labelId="suppressions-label" label="Suppression File" onChange={handleSuppressionFileChange} defaultValue="" size="medium" value={suppressionFile}>
                    <MenuItem value="none">Not Used</MenuItem>
                    {suppressions &&  suppressions.map((fm, index) => (
                        <MenuItem value={fm.id} key={index}>{fm.uploadedFilename}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </ComponentBlock>
            </Stack>

            <Box sx={{ width: "100%", height: "400px" }}>
              <RadiusMap storefront={props.storefront} radiusDistance={radiusDistance} zipCode={searchZip} />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button variant="contained" onClick={handleSearchButtonClicked} disabled={activeSearch} sx={{ mt: 2, backgroundColor: props.storefront.activeColors.mainPage.pageButtonBackground }} size="large" >Search</Button>
                <Button variant="contained" onClick={handleCancelButtonClicked} disabled={!activeSearch} sx={{ mt: 2, backgroundColor: props.storefront.activeColors.mainPage.pageButtonBackground, ml: 2 }} size="large" >Cancel</Button>
              </Grid>
              <Grid item xs={6}>
                {activeSearch &&
                  <Stack spacing={1} sx={{ mt: 1 }}>
                    <Typography variant="body2">Finding your leads...</Typography>
                    <BorderLinearProgress variant="determinate" value={(iterations / maxIterations) * 100} />
                  </Stack>
                }
              </Grid>
              <Grid item xs={10}>
                {blueprintCountError != "" &&
                  <Alert severity="error" sx={{ mt: 2 }}>{blueprintCountError}</Alert>
                }
                {buildQueryWarningMsg != "" && <Alert sx={{ m: 1 }} severity="warning">{buildQueryWarningMsg}</Alert>}
              </Grid>
            </Grid>
            {searchResult && searchResult.count > 0 &&
              <Alert severity="success" sx={{ mt: 2 }}>
                Found {getPurchaseCount()} records within {searchResult.radius} miles of {searchZip}
              </Alert>
            }
          </Stack>
        </Paper>

        {searchResult && searchResult.count > 0 &&
          <Paper elevation={3} sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>Purchase Leads</Typography>
            <Stack>
              <Stack direction="row" sx={{ ml: 2 }}>
                <Typography sx={{ width: 170 }}>Leads:</Typography>
                <Typography>{getPurchaseCount().toLocaleString()}</Typography>
              </Stack>
              <Stack direction="row" sx={{ ml: 2 }}>
                <Typography sx={{ width: 170 }}>Price/Lead:</Typography>
                <Typography>$0.20</Typography>
              </Stack>
              {walletBalance && walletBalance.balance && walletBalance.balance > 0 &&
                <Stack direction="row" sx={{ ml: 2 }}>
                  <Typography sx={{ width: 170 }}>Wallet Balance:</Typography>
                  <Typography>{walletBalance?.balance.toLocaleString()}</Typography>
                </Stack>
              }

              {walletBalance && walletBalance.balance && walletBalance.balance >= getPurchaseCount()
                ? <Button variant="contained" color="primary" sx={{ m: 2, width: 220 }} onClick={buyNowWithWalletButtonClicked}>Buy Now with Wallet</Button>
                : <Stack sx={{ ml: 2 }}>
                  <Stack direction="row">
                    <Typography sx={{ width: 170 }}>Sub-Total:</Typography>
                    <Typography>{formatter.format(calcSubtotal())}</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography sx={{ width: 170 }}>Wallet Credits Used:</Typography>
                    <Typography>{appliedWalletCredits().toLocaleString()}</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography sx={{ width: 170 }}>Total:</Typography>
                    <Typography>{formatter.format(calcTotal())}</Typography>
                  </Stack>
                  {calcPotentialWalletSavings() > 0 &&
                    <Alert sx={{ ml: 2, mr: 3, mt: 1 }} severity="info">Savings Alert! You can save {formatter.format(calcPotentialWalletSavings())} on this purchase by funding your wallet first. Click 'Wallet' in the left menu for more information.</Alert>
                  }
                  <Button variant="contained" color="primary" sx={{ m: 2, width: 220 }} onClick={buyNowWithWalletButtonClicked}>Buy Now</Button>
                </Stack>
              }
            </Stack>
          </Paper>
        }
      </Grid>
    </Container>
  );
}
