import React, { createContext, useState, useContext, Dispatch, SetStateAction } from "react";
import { User } from "../models/UsersResponse";


const TSGUserContext = createContext({
    tsgUser: {} as Partial<User>,
    setTSGUser: {} as Dispatch<SetStateAction<Partial<User>>>
});

export const TSGUserContextProvider = ({ children, value = {} as User }: { children: React.ReactNode; value?: Partial<User> }) => {
    const [tsgUser, setTSGUser] = useState(value);

    return (
        <TSGUserContext.Provider value={{ tsgUser, setTSGUser}}>
            {children}
        </TSGUserContext.Provider>
    );
};

export const useTSGUser = () => {
    const context = useContext(TSGUserContext);
    if (!context) {
        throw new Error("useTSGUser must be used within a TSGUserContextProvider");
    }
    
    return context;
}