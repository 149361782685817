import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Storefront } from '../models/StorefrontResponse';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from 'react';
import { APIEndpoint, EndpointType, httpPut } from '../utils/apiService';
import { SuppressionListResponse, SuppressionListStatus } from '../models/Suppression';
import CloseIcon from '@mui/icons-material/Close';
import { useS3FileS3Upload } from '../hooks/useS3FileUpload';
import { DropzoneArea } from 'mui-file-dropzone';
import { SuppressionList } from '../models/Suppression';
import { useSuppressionAnalysis } from '../hooks/useSuppressionAnalysis';
import { useTSGUser } from '../contexts/TSGUserContext';

interface Props {
    storefront: Storefront
    referenceID: string
    close(): void
}

export default function SuppressionUpload2(props: Props) {
    const [startUpload, setStartUpload] = useState(false)
    const [firstNameMap, setFirstNameMap] = useState("Not Used")
    const [lastNameMap, setLastNameMap] = useState("Not Used")
    const [addressMap, setAddressMap] = useState("Not Used")
    const [address2Map, setAddress2Map] = useState("Not Used")
    const [zipMap, setZipMap] = useState("Not Used")
    const [phoneMap, setPhoneMap] = useState("Not Used")
    const [emailMap, setEmailMap] = useState("Not Used")
    const [warningMessage, setWarningMessage] = useState("")
    const [validationMessage, setValidationMessage] = useState("")
    const [userID, setUserID] = useState("")
    const [files, setFiles] = useState<File[]>([]);
    const [file, setFile] = useState<File>({} as File);
    const [suppressionUploadList, setSuppressionUploadList] = useState<SuppressionList>()
    const [showFieldMapping, setShowFieldMapping] = useState(false)
    const [showUploadSelection, setShowUploadSelection] = useState(true)
    const [suppressionAnalysisList, setSuppressionAnalysisList] = useState<SuppressionList>()
    const [suppressionAnalysisRefreshKey, setSuppressionAnalysisRefreshKey] = useState(0)
    const [suppressionCompleted, setSuppressionCompleted] = useState(false)

    // Hooks
    const { tsgUser } = useTSGUser();
    const {S3UploadResponse, S3UploadCompleted, S3UploadError, S3UploadProgress, S3UploadSuppressionID} = useS3FileS3Upload(file, startUpload, userID, props.referenceID)
    const { suppressionAnalysis, suppressionAnalysisError} = useSuppressionAnalysis(suppressionAnalysisList, suppressionAnalysisRefreshKey)

    useEffect(() => {
        setUserID(tsgUser?.id || "")
    }, [tsgUser]);

    useEffect(() => {
        if (S3UploadCompleted && S3UploadError == "") {
            analyzeUploadedFile()
        }
    }, [S3UploadCompleted]);

    useEffect(() => {
        if (suppressionAnalysis && suppressionAnalysisError == "") {
            setSuppressionUploadList(suppressionAnalysis)
            setShowFieldMapping(true)
            setShowUploadSelection(false)
            setDefaultMappings(suppressionAnalysis)
        }
    }, [suppressionAnalysis]);

    const uploadButtonClicked = () => {
        setValidationMessage("")
        setSuppressionAnalysisRefreshKey(0)
        setSuppressionCompleted(false)

        if (files.length === 0) {
            setValidationMessage("Please select a file to upload before clicking the upload button.")
            return
        }
        setFile(files[0])
        setStartUpload(true)
        setShowFieldMapping(false)
    }

    const analyzeUploadedFile = () => {
        const suppressionList: SuppressionList = {
            id: S3UploadSuppressionID,
            status: SuppressionListStatus.pending,
            fields: [],
            columnMappings: {},
            createdAt: '',
            updatedAt: '',
            referenceID: props.referenceID,
            listID: 0,
            s3Uri: getS3Uri(),
            recordCount: 0,
            info: '',
            uploadedFilename: file.name,
            minifiedS3Uri: '',
            minifiedErrors: []
        }

        console.log("Suppression List for Analysis: ", suppressionList)
        setSuppressionAnalysisList(suppressionList)
        setSuppressionAnalysisRefreshKey(1)
    }

    const getS3Uri = () => {
        if (S3UploadResponse) {
            const rootUrl = S3UploadResponse.url.split("?")[0]
            let s3Uri = rootUrl.replace("https://", "s3://")
            s3Uri = s3Uri.replace(".s3.us-west-2.amazonaws.com", "")
            return s3Uri
        }
        return ""
    }

    const setDefaultMappings = (list: SuppressionList) => {
        if (list) {
            // loop through each of the columnMappings and set default value if found
            if (list.columnMappings) {
                for (const [key, value] of Object.entries(list.columnMappings)) {
                    if (key === "firstName") {
                        setFirstNameMap(list.fields[value])
                    } else if (key === "lastName") {
                        setLastNameMap(list.fields[value])
                    } else if (key === "address") {
                        setAddressMap(list.fields[value])
                    } else if (key === "zip") {
                        setZipMap(list.fields[value])
                    } else if (key === "phone") {
                        setPhoneMap(list.fields[value])
                    } else if (key === "address2") {
                        setAddress2Map(list.fields[value])
                    } else if (key === "email") {
                        setEmailMap(list.fields[value])
                    }
                }
            }
        }
    }

    const submitButtonClicked = () => {
        setWarningMessage("")
        // Validate that required fields are mapped
        if (phoneMap == "-1" && (addressMap == "-1" || zipMap == "-1")) {
            setWarningMessage("Either Phone or both Address and Zip must be mapped")
            return
        }

        if (phoneMap == "-1") {
            if (addressMap == "-1") {
                setWarningMessage("Since no phone is specified, the Address field must be mapped to a valid field")
                return
            }
    
            if (zipMap == "-1") {
                setWarningMessage("Since no phone is specified, the Zip field must be mapped to a valid field")
                return
            }
        }

        const suppression = suppressionUploadList

        if (suppression) {
            suppression.status = SuppressionListStatus.ready_for_processing
            suppression.columnMappings = {}

            if (addressMap != "Not Used") {
                suppression.columnMappings["address"] = getIdxFromFieldName(addressMap)
            }

            if (zipMap != "Not Used") {
                suppression.columnMappings["zip"] = getIdxFromFieldName(zipMap)
            }

            if (firstNameMap != "Not Used") {
                suppression.columnMappings["first_name"] = getIdxFromFieldName(firstNameMap)
            }

            if (lastNameMap != "Not Used") {
                suppression.columnMappings["last_name"] = getIdxFromFieldName(lastNameMap)
            }

            if (address2Map != "Not Used") {
                suppression.columnMappings["address2"] = getIdxFromFieldName(address2Map)
            }

            if (phoneMap != "Not Used") {
                suppression.columnMappings["phone"] = getIdxFromFieldName(phoneMap)
            }

            if (emailMap != "Not Used") {
                suppression.columnMappings["email"] = getIdxFromFieldName(emailMap)
            }

            console.log("Colum Mappings: " + JSON.stringify(suppression.columnMappings))

            try {
                const apiURL = APIEndpoint(EndpointType.Suppressions) + "/" + suppression.id;
                httpPut(apiURL, JSON.stringify(suppression))
                    .then((data) => {
                        const response = data as SuppressionListResponse;

                        if (response.status == 'error') {
                            setWarningMessage("Error creating transaction! " + response.errorMessage)
                        } else {
                            setSuppressionCompleted(true)
                        }
                    })
                    .catch((error) => {
                        if (error instanceof Error) {
                            console.error("Error:", error.message);
                            setWarningMessage(error.message)
                          } else {
                            console.error("An unknown error occurred.");
                          }
                    });
            } catch (error) {
                console.log("Global PUT Catch Error submitting suppression list: " + error)
                setWarningMessage("failed to submit suppression list")
            }
        }
    }

    const getIdxFromFieldName = (fieldName: string): number => {
        if (!suppressionUploadList) {
            return -1
        }

        const idx = suppressionUploadList.fields.indexOf(fieldName)
        console.log(fieldName, idx)
        return idx
    }

    const handleCompletionConfirmationClose = () => {
        props.close()
    }

    return (
        <Container maxWidth="xl" disableGutters={true} sx={{}}>
            <Paper sx={{pb:1}}>
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Typography variant="h5" sx={{ ml: 1, color: props.storefront.activeColors.mainPage.pageText }}>Suppression List Upload</Typography>
                    <IconButton sx={{ marginTop: -.5, color: props.storefront.activeColors.mainPage.pageText }} size="small" type="button" onClick={() => props.close()} >
                        <CloseIcon />
                    </IconButton>
                </Stack>

                {showUploadSelection &&
                    <Box sx={{ }}>
                        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={1} sx={{ ml: 1 }}>
                            <Typography variant="body1" sx={{ color: props.storefront.activeColors.mainPage.pageText }}>Submit a suppression file to ensure that you don't receive duplicate data when placing orders.</Typography>
                            <Typography variant="body1" sx={{ color: props.storefront.activeColors.mainPage.pageText, fontWeight: "bold" }}>File Requirements:</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Supported file types: .csv, .xls, .xlsx</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Minimum required fields are Address and Zip</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Supported fields: First Name, Last Name, Address, Address 2, Zip, Phone, Email & Company</Typography>
                        </Stack>

                        <Box sx={{ m:1}}>
                        <DropzoneArea fileObjects={files} onChange={(files) => setFiles(files)} maxFileSize={100000000} showAlerts={false} />
                        </Box>
                        <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ ml: 1, mb: 1 }}>
                            <Button sx={{ml:1, mb:1}} variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={uploadButtonClicked}>Upload</Button>
                            {validationMessage != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1, pb:1 }}>{validationMessage}</Alert>}
                        </Stack>

                        <Grid sx={{mt: 1}}>
                        {S3UploadProgress > 0 &&
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1 }}>
                                <Typography variant="body1" sx={{ width: 120 }}>Uploading file...</Typography>
                                <Box sx={{ width: '81%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={S3UploadProgress} />
                                </Box>
                            </Stack>
                        }
                        {/* {S3UploadError == "" && S3UploadCompleted && <Alert variant="filled" severity="info" sx={{ mt: 2 }}>The suppression file was uploaded successfully!</Alert>} */}
                        {suppressionAnalysisError != "" && <Alert variant="filled" severity="info" sx={{ mt: 2 }}>Failed to analyze file - {suppressionAnalysisError}</Alert>}
                    </Grid>
                    </Box>
                }

                {showFieldMapping
                    ? <Grid sx={{ mt: 1, ml: 1, mr: 1 }}>
                        {suppressionUploadList && suppressionUploadList.id != "" &&
                            <Grid>
                                <Typography variant="h6">Field Mapping:</Typography>
                                <Typography variant="body1">In order to complete the suppression file submission process, fields must be mapped so we know which ones to use. Either Phone or both Address and Zip are required. PRO TIP: Specify only the fields you want to match on...this is typically Phone or Address & Zip.</Typography>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2, pb: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>First Name:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setFirstNameMap(e.target.value))} size="small" value={firstNameMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Last Name:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setLastNameMap(e.target.value))} size="small" value={lastNameMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Address:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setAddressMap(e.target.value))} size="small" value={addressMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Address 2:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setAddress2Map(e.target.value))} size="small" value={address2Map}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Zip Code:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setZipMap(e.target.value))} size="small" value={zipMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Phone:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setPhoneMap(e.target.value))} size="small" value={phoneMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Email:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setEmailMap(e.target.value))} size="small" value={emailMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {warningMessage != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{warningMessage}</Alert>}
                                <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, pb: 1 }}>
                                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={submitButtonClicked}>Complete Submission</Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    : <Grid>
                    </Grid>
                }

                {S3UploadError && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{S3UploadError}</Alert>}
            </Paper>
            <Dialog
                open={suppressionCompleted}
                keepMounted
                onClose={handleCompletionConfirmationClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Suppression Upload Complete</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    The file has been submitted for processing. You will receive an email notification at '{tsgUser?.email}' within the next several minutes upon its completion.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" onClick={handleCompletionConfirmationClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
