import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Storefront } from '../models/StorefrontResponse';
import { WebsocketMessage, WebsocketCommandType, RadiusCountResult, RadiusCountWebsocketResponse } from '../models/Websocket';
import { useState, useEffect, useCallback } from 'react';
import { useZipCodeCounts } from "../hooks/useZipCodeCounts";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorFallBack from "../components/ErrorFallBack";
import { Product } from '../models/Products';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import { useMap } from '../hooks/useMap'
import Alert from '@mui/material/Alert';
import { CartItem } from '../models/Store';
import { genUniqueId } from "../utils/GenerateUniqueID";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ZipCodeCount } from '../models/ZipCodeCountsResponse';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Grid, Input, LinearProgress, Popover, Slider, Switch } from '@mui/material';
import { useZip4 } from '../hooks/useZip4';
import useWebSocket, { Options, ReadyState } from 'react-use-websocket';
import { APIEndpoint, EndpointType } from '../utils/apiService';
import { Auth } from 'aws-amplify';
import { useLog } from '../hooks/useLogs';
import { useTSGUser } from '../contexts/TSGUserContext';

interface FormProps {
    zip: string
    selectionMode: string
    radiusDistance: number
    radiusPoint: number[]
    storefront: Storefront
    products: Product[]
    closeAddProductForm(): void
    addProducts(cartItems: CartItem[]): void
    zipCodeCountChangedCallback(zipCodeCount: ZipCodeCount): void
}

interface customCount {
    audienceID: number
    count: number
}

export default function AddProductForm(props: FormProps) {
    const { logWarn, logInfo, logError } = useLog();
    const { tsgUser } = useTSGUser();
    const [zipCode, setZipCode] = useState("");
    const [cityState, setCityState] = useState("");
    const [radiusPoint, setRadiusPoint] = useState<number[]>([]);
    const [radiusDistance, setRadiusDistance] = useState(0);
    const [radiusAudienceIDs, setRadiusAudienceIDs] = useState<number[]>([]);
    const [zip4Radius, setZip4Radius] = useState("");
    const { zipCodeCounts, zipCodeLoading, zipCodeError } = useZipCodeCounts(zipCode, "", "", "", false, 0, "");
    const { zip4, zip5, zip4Loading, zip4Error } = useZip4(radiusPoint);
    const [map, actions] = useMap<string, string>([])
    const [showAddProductWarning, setShowAddProductWarning] = useState("none")
    const [addProductWarningMsg, setAddProductWarningMsg] = useState("")
    const [totalLeadsSelected, setTotalLeadsSelected] = useState(0)
    const [noDNC, setNoDNC] = useState(false);
    const [emails, setEmails] = useState(false);
    const [openCustomProductSelector, setOpenCustomProductSelector] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [customQuantity, setCustomQuantity] = useState(0);
    const [customQuantityMax, setCustomQuantityMax] = useState(0);
    const [customAudienceID, setCustomAudienceID] = useState(0)
    const [customQuantityName, setCustomQuantityName] = useState("")
    const [countOverrides, setCountOverrides] = useState<customCount[]>([])
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
    const [socketUrl, setSocketUrl] = useState('wss://2usnaow8h9.execute-api.us-west-2.amazonaws.com/dev/');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([]);
    const [radiusCounts, setRadiusCounts] = useState<RadiusCountResult[]>([])
    const [enableWebSocket, setEnableWebSocket] = useState(false)
    const [productFilterType, setProductFilterType] = useState("leads")

    const websocketOptions: Options = {
        heartbeat: {
            message: 'ping',
            returnMessage: 'pong',
            timeout: 60000, // 1 minute, if no response is received, the connection will be closed
            interval: 25000 // every 25 seconds, a ping message will be sent
        }
    }

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, websocketOptions, enableWebSocket);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        setZipCode(props.zip)
        setRadiusPoint(props.radiusPoint)
        setRadiusDistance(props.radiusDistance)
    });

    useEffect(() => {
        if (lastMessage !== null) {
            logInfo("Received message: " + JSON.stringify(lastMessage.data))

            try {
                const response: RadiusCountWebsocketResponse = JSON.parse(lastMessage.data)
                if (response.status == "completed") {
                    logInfo("Adding COMPLETED result to radius counts: " + response.audienceID + ", Count: " + response.result.count)
                    setRadiusCounts((curr) => [...curr, response.result])
                } else if (response.status == "failed") {
                    // If the respsone moreInfo field contains "504 Gateway Timeout", then we need to resend the message
                    if (response.moreInfo.includes("504 Gateway Timeout")) {
                        logWarn(`504 Gateway Timeout for audience ${response.audienceID}...resending message`)
                        const wsCommand: WebsocketMessage = {
                            command: WebsocketCommandType.AudienceCount,
                            customerID: tsgUser?.id || "",
                            audienceCommandInfo: {
                                zip4Radius: zip4Radius,
                                audienceID: response.audienceID,
                                suppressOrders: false
                            }
                        }
            
                        if (tsgUser?.id != "") {
                            wsCommand.audienceCommandInfo!.suppressOrders = true
                        }
                        sendMessage(JSON.stringify(wsCommand))
                    } else {
                        // In a failed condition, no result is returned, so we need to create a new blank result object
                        const audienceID = parseInt(response.audienceID)
                        const emptyResult: RadiusCountResult = {
                            audienceID: audienceID,
                            count: 0,
                            blueprintID: '',
                            zip4Radius: '',
                            ordersSuppressedCount: 0
                        }

                        logInfo("Adding FAILED result to radius counts: " + response.audienceID)
                        setRadiusCounts((curr) => [...curr, emptyResult])
                    }

                }
            } catch (error) {
                logError("Error parsing websocket message: " + error)
            }
            setMessageHistory((prev) => prev.concat(lastMessage));
        }
    }, [lastMessage]);

    useEffect(() => {
        // TODO: Check message history to see if everything is completed
        logInfo("Message History Count: " + messageHistory.length)
    }, [messageHistory])

    useEffect(() => {
        filterProducts()
    }, [productFilterType])

    useEffect(() => {
        getTotalLeadCount()
    }, [map])

    useEffect(() => {
        logInfo("Websocket connection status: " + connectionStatus)
        if (readyState == ReadyState.CLOSED) {
            setEnableWebSocket(false)
        }
    }, [readyState])

    useEffect(() => {
        if (zipCodeCounts.length > 0) {
            setCityState(zipCodeCounts[0].cityCap + ", " + zipCodeCounts[0].state)
            props.zipCodeCountChangedCallback(zipCodeCounts[0])
        }
        resetForm()
        filterProducts()
    }, [zipCodeCounts])

    useEffect(() => {
        actions.reset()
        filterProducts()
        getTotalLeadCount()
    }, [noDNC, emails])


    useEffect(() => {
        if (tsgUser?.id != "") {
            configureWebsocketURL()
        }
    }, [tsgUser])

    useEffect(() => {
        filterProducts()
    }, [props.products])

    useEffect(() => {
        if (props.selectionMode == "radius") {
            configureWebsocketURL()
        }
        setEnableWebSocket(props.selectionMode == "radius")
    }, [props.selectionMode])

    useEffect(() => {
        if (zip4 != "" && zip5 != "" && radiusDistance > 0) {
            const zip4radius = zip5 + "+" + zip4 + "-" + radiusDistance
            setZip4Radius(zip4radius)
        }
    }, [zip4, zip5, radiusDistance])

    useEffect(() => {
        logInfo("Zip4Radius: " + zip4Radius)
        if (radiusAudienceIDs.length > 0 && zip4Radius != "") {
            getAllRadiusCounts()
        }
    }, [zip4Radius])

    useEffect(() => {
        if (customQuantityName != "") {
            setOpenCustomProductSelector(true)
        }
    }, [customQuantityName])

    useEffect(() => {
        let isCompleted = true
        for (const audienceID of radiusAudienceIDs) {
            let found = false
            for (const radiusCount of radiusCounts) {
                if (radiusCount.audienceID == audienceID) {
                    found = true
                    break
                }
            }

            if (!found) {
                isCompleted = false
                break
            }
        }

        if (isCompleted) {
            logInfo("All radius counts are completed")
        }
    }, [radiusCounts])

    const configureWebsocketURL = async () => {
        if (tsgUser?.id == "") {
            logWarn("No customer ID...aborting websocket URL configuration")
            return // Websocket requires auth so we can't use it without a customer ID
        }

        const currentSession = Auth.currentSession()
        const token = (await currentSession).getAccessToken()
        const accessToken = token.getJwtToken()

        setSocketUrl(APIEndpoint(EndpointType.Websocket) + "?authorization=" + accessToken);
    }

    const isProductSelected = (productID: string): boolean => {
        return map.has(productID)
    }

    const handleNoDNCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNoDNC(event.target.checked);
    };

    const handleEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmails(event.target.checked);
        getTotalLeadCount()
    };

    const handleProductSelected = (selectedProductID: string, checked: boolean) => {
        // Remove product
        if (!checked) {
            logInfo("removing product: " + selectedProductID)
            actions.remove(selectedProductID)
            return
        }

        // Add product
        logInfo("adding product: " + selectedProductID)
        actions.set(selectedProductID, selectedProductID)
    };

    const getAllRadiusCounts = useCallback(() => {
        if (zip4Radius == "") {
            return
        }
        setEnableWebSocket(true)
        logInfo("Getting counts for radius: " + zip4Radius + "...")

        if (readyState != ReadyState.OPEN) {
            logWarn("Websocket not open, not sending message. Ready State: " + readyState + " Websocket Enabled: " + enableWebSocket)
            return
        }

        setMessageHistory([])
        setRadiusCounts([])
        
        for (const audienceID of radiusAudienceIDs) {
            const wsCommand: WebsocketMessage = {
                command: WebsocketCommandType.AudienceCount,
                customerID: tsgUser?.id || "",
                audienceCommandInfo: {
                    zip4Radius: zip4Radius,
                    audienceID: audienceID.toString(),
                    suppressOrders: false
                }
            }

            if (tsgUser?.id != "") {
                wsCommand.audienceCommandInfo!.suppressOrders = true
            }

            logInfo("Sending Radius command to websocket: " + JSON.stringify(wsCommand))
            // We are getting frequent 504 gateway errors from LG, so we are going to throttle the requests
            // to one every 1000 milliseconds
            setTimeout(() => {
                sendMessage(JSON.stringify(wsCommand))
            }, 1000)
            //sendMessage(JSON.stringify(wsCommand))
        }
    }, [zip4Radius]);

    const handleAddToCartButtonClick = () => {
        setShowAddProductWarning("none")
        const cartItems: CartItem[] = []
        map.forEach((value: string, key: string) => {
            const prodID = key
            let quantity = 0

            const prod = getProductByID(prodID)
            if (prod != undefined) {
                if (quantity == 0) {
                    quantity = getLeadCount(prod.audienceID)
                }


                const cartItem: CartItem = {
                    id: genUniqueId(),
                    product: prod,
                    quantity: quantity,
                    postalCodes: [props.zip]
                }

                if (props.selectionMode == "radius") {
                    cartItem.radiusSettings = {
                        zip4Radius: zip4Radius,
                        distance: props.radiusDistance.toString()
                    }
                    cartItem.postalCodes = []
                }

                cartItems.push(cartItem)
            }
        });

        logInfo("Adding " + cartItems.length + " products to cart")
        if (cartItems.length > 0) {
            props.addProducts(cartItems)
        } else {
            setAddProductWarningMsg("No products were selected")
            setShowAddProductWarning("inline")
        }

        // Remove all products from the map
        actions.reset()
    };

    const getTotalLeadCount = () => {
        let totalLeads = 0
        map.forEach((value: string, key: string) => {
            const prodID = key
            let quantity = 0

            const prod = getProductByID(prodID)
            if (prod != undefined) {
                if (quantity == 0) {
                    quantity = getLeadCount(prod.audienceID)
                }

            }
            totalLeads += quantity
        });

        setTotalLeadsSelected(totalLeads)
    }

    const getProductByID = (productID: string): Product | undefined => {
        for (const prod of props.products) {
            if (productID == prod.id) {
                return prod
            }
        }

        return undefined
    }

    const getLeadCount = (audienceID: number): number => {
        if (props.selectionMode == "radius") {
            for (const radiusCount of radiusCounts) {
                if (radiusCount.audienceID == audienceID) {
                    if (radiusCount.count != undefined) {
                        return radiusCount.count
                    } else {
                        return 0
                    }
                }
            }
            return 0
        }

        if (zipCodeCounts.length == 0) {
            return 0
        }

        // Check to see if there is a custom count
        for (const countOverride of countOverrides) {
            if (countOverride.audienceID == audienceID) {
                return countOverride.count
            }
        }

        let leadCount = 0
        for (const zipCodeCount of zipCodeCounts) {
            if (zipCodeCount.listCounts) {
                for (const listCount of zipCodeCount.listCounts) {
                    if (listCount.audienceID == audienceID) {
                        leadCount = listCount.count
                        break
                    }
                }
            }
        }

        return leadCount
    }

    const isCountAvailable = (audienceID: number): boolean => {
        if (props.selectionMode == "radius") {
            for (const radiusCount of radiusCounts) {
                if (radiusCount.audienceID == audienceID) {
                    return true
                }
            }
            return false
        }

        return true
    }

    const getLeadsPrice = (product: Product): string => {
        return formatter.format(getLeadCount(product.audienceID) * product.priceInfo.price)
    }

    const commonStyles = {
        bgcolor: "#cdcccf",
        height: '1px',
        smallIcon: {
            width: 30,
            height: 30,
        },
    };

    const saveCustomQuantity = () => {
        const custCount: customCount = {
            audienceID: customAudienceID,
            count: customQuantity
        }

        // Check to see if the product is already in the array
        let found = false
        for (let i = 0; i < countOverrides.length; i++) {
            if (countOverrides[i].audienceID == customAudienceID) {
                countOverrides[i].count = customQuantity
                found = true
                break
            }
        }

        if (!found) {
            setCountOverrides([...countOverrides, custCount])
        } else {
            const updatedOverrides = countOverrides.map((item) => {
                if (item.audienceID == customAudienceID) {
                    return custCount
                }
                return item
            })
            setCountOverrides(updatedOverrides)
        }

        getTotalLeadCount()
        handleCustomProductSelectorClose()
    }

    const handleCustomProductSelectorClose = () => {
        setOpenCustomProductSelector(false)
        setCustomQuantityName("")
    };

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const quantity = newValue as number
        setCustomQuantity(quantity);
    };

    const investorLeadSwitchChanged = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
        let filter = "leads"
        if (checked) {
            filter = "investor_leads"
        } 
        setProductFilterType(filter)
    }

    const filterProducts = () => {
        const filtered: Product[] = []
        const audienceIDs: number[] = []

        if (!props.products) {
            return filtered
        }

        for (const product of props.products) {
            if (noDNC && emails) {
                if (product.features.noDNC && product.features.phonesEmails) {
                    if (props.selectionMode == "zipcode") {
                        if (productFilterType == product.productType) {
                            filtered.push(product)
                            audienceIDs.push(product.audienceID)
                        }
                    } else {
                        if (product.groupName != "Absentee Leads") {
                            if (productFilterType == product.productType) {
                                filtered.push(product)
                                audienceIDs.push(product.audienceID)
                            }
                        }
                    }
                }
            } else if (!noDNC && !emails) {
                if (!product.features.noDNC && !product.features.phonesEmails) {
                    if (props.selectionMode == "zipcode") {
                        if (productFilterType == product.productType) {
                            filtered.push(product)
                            audienceIDs.push(product.audienceID)
                        }
                    } else {
                        if (product.groupName != "Absentee Leads") {
                            if (productFilterType == product.productType) {
                                filtered.push(product)
                                audienceIDs.push(product.audienceID)
                            }
                        }
                    }
                }
            } else if (noDNC && !emails) {
                if (product.features.noDNC && !product.features.phonesEmails) {
                    if (props.selectionMode == "zipcode") {
                        if (productFilterType == product.productType) {
                            filtered.push(product)
                            audienceIDs.push(product.audienceID)
                        }
                    } else {
                        if (product.groupName != "Absentee Leads") {
                            if (productFilterType == product.productType) {
                                filtered.push(product)
                                audienceIDs.push(product.audienceID)
                            }
                        }
                    }
                }
            } else if (!noDNC && emails) {
                if (!product.features.noDNC && product.features.phonesEmails) {
                    if (props.selectionMode == "zipcode") {
                        if (productFilterType == product.productType) {
                            filtered.push(product)
                            audienceIDs.push(product.audienceID)
                        }
                    } else {
                        if (product.groupName != "Absentee Leads") {
                            if (productFilterType == product.productType) {
                                filtered.push(product)
                                audienceIDs.push(product.audienceID)
                            }
                        }
                    }
                }
            }
        }

        setRadiusAudienceIDs(audienceIDs)
        return setFilteredProducts(filtered)
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const resetForm = () => {
        actions.reset()
        setCountOverrides([])
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomQuantity(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (customQuantity < 0) {
            setCustomQuantity(0);
        } else if (customQuantity > customQuantityMax) {
            setCustomQuantity(customQuantityMax);
        }
    };

    return (
        <React.Fragment>
            <Stack>
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginTop: 8 }}>
                    {props.zip == "" 
                    ? <Typography variant="h6" sx={{ color: props.storefront.activeColors.addProductForm.titleText }}></Typography> 
                    : <Typography variant="h6" sx={{ color: props.storefront.activeColors.addProductForm.titleText }}>&nbsp;Zip Code: {props.zip}</Typography>
                    }
                    
                    <IconButton sx={{ marginTop: -.5, color: props.storefront.activeColors.addProductForm.iconForeground }} size="small" type="button" onClick={() => { resetForm(); props.closeAddProductForm() }} >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {props.zip != "" 
                    ? <Typography variant="h6" sx={{ color: props.storefront.activeColors.addProductForm.titleText }}>&nbsp;{cityState}</Typography>
                    : <Typography variant="h6" sx={{ color: props.storefront.activeColors.addProductForm.titleText }}>&nbsp;</Typography>
                }
                <Box>
                    <Box sx={{ backgroundColor: "#e8e8e8", color: props.storefront.activeColors.addProductForm.titleText, mb: -1, ml: -1 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch />} label="Investor Leads" sx={{ml:.5}} onChange={investorLeadSwitchChanged} />
                        </FormGroup>
                    </Box>
                    <Accordion sx={{ backgroundColor: "#e8e8e8", color: props.storefront.activeColors.addProductForm.titleText }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: props.storefront.activeColors.addProductForm.iconForeground }} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Stack direction="row" sx={{ ml: -1 }}>
                                <Typography fontWeight="bold">Advanced Options</Typography>
                                <HtmlTooltip title={
                                    <React.Fragment>
                                        <Stack>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Advanced Options</Typography>
                                            <Typography variant="body2">Leads can be filtered to remove do-not-call numbers, require email addresses or both. There is a $100 additional charge for applying these options which will appear in the shopping cart. However, if you use your wallet to purchase the leads, there is no additional charge.</Typography>
                                            <a href="https://www.theshare.group/leads" target="_blank">More Information</a>
                                        </Stack>
                                    </React.Fragment>
                                }
                                >
                                    <Box sx={{ marginTop: 0, color: props.storefront.activeColors.addProductForm.iconForeground }}><InfoOutlinedIcon /></Box>

                                </HtmlTooltip>
                            </Stack>

                        </AccordionSummary>
                        <AccordionDetails>
                            <FormGroup sx={{ mt: -3, mb: -1, ml: -1, mr: -2 }}>
                                <FormControlLabel control={<Checkbox checked={noDNC} onChange={handleNoDNCChange} sx={{ color: props.storefront.activeColors.addProductForm.iconForeground }} />} label="Remove records on Do-Not-Call" />
                                {productFilterType == "leads" &&
                                    <FormControlLabel control={<Checkbox checked={emails} onChange={handleEmailsChange} sx={{ color: props.storefront.activeColors.addProductForm.iconForeground }} />} label="Remove records without email" />
                                }
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <React.Fragment>
                    {zipCodeLoading || zip4Loading
                        ? <LoadingSpinner title="Analyzing Counts..." textColor={props.storefront.activeColors.addProductForm.productText} />
                        : zipCodeError || zip4Error
                            ? <ErrorFallBack message={zipCodeError} />
                            :
                            <Stack direction="column" sx={{ marginLeft: 1 }}>
                                {filteredProducts.map((prod) => (
                                    <Stack direction="column" key={prod.id} >
                                        <Box sx={{ ...commonStyles, marginRight: 1 }} />
                                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                                            <Typography sx={{ marginTop: 1, fontWeight: "bold", color: props.storefront.activeColors.addProductForm.productText }}>{prod.name}:&nbsp;</Typography>
                                            <HtmlTooltip title={
                                                <React.Fragment>
                                                    <Stack>
                                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: props.storefront.activeColors.addProductForm.productText }}>{prod.name}</Typography>
                                                        <Typography variant="body2" sx={{ color: props.storefront.activeColors.addProductForm.productText }}>{prod.detailedDescription}</Typography>
                                                        <a href={prod.infoURL} target="_blank">More Information</a>
                                                    </Stack>
                                                </React.Fragment>
                                            }
                                            >
                                                <Box sx={{ marginTop: 1, marginRight: 1, color: props.storefront.activeColors.addProductForm.iconForeground }}><InfoOutlinedIcon /></Box>

                                            </HtmlTooltip>
                                        </Stack>
                                        <Stack direction="row" sx={{ mt: -1 }} alignItems="center" justifyContent="space-between">
                                            <Stack direction="row" >
                                                <Checkbox sx={{ color: props.storefront.activeColors.addProductForm.iconForeground, marginLeft: -1, marginTop: -1 }} checked={isProductSelected(prod.id)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleProductSelected(prod.id, event.target.checked) }} ></Checkbox>
                                                {isCountAvailable(prod.audienceID)
                                                    ? <Typography sx={{ marginTop: 0, color: props.storefront.activeColors.addProductForm.productText }}>{getLeadCount(prod.audienceID).toLocaleString()} leads</Typography>
                                                    : <Stack>
                                                        <LinearProgress sx={{ marginTop: .25, width: 140 }} color="success" />
                                                        <Typography sx={{ color: props.storefront.activeColors.addProductForm.productText }} variant="subtitle2">Checking availability...</Typography>
                                                    </Stack>
                                                }
                                                <Popover
                                                    open={openCustomProductSelector}
                                                    onClose={handleCustomProductSelectorClose}
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <Grid sx={{ width: 300, marginTop: 1 }}>
                                                        <Grid container spacing={1} alignItems="center" sx={{ marginLeft: 1 }}>
                                                            <Typography id="input-slider" sx={{ marginTop: .75 }}>
                                                                Quantity:
                                                            </Typography>
                                                            <Grid item xs >
                                                                <Slider sx={{ maxWidth: 100 }}
                                                                    value={typeof customQuantity === 'number' ? customQuantity : 0}
                                                                    onChange={handleSliderChange}
                                                                    aria-labelledby="input-slider"
                                                                    min={1}
                                                                    max={customQuantityMax}
                                                                />
                                                            </Grid>
                                                            <Grid item sx={{ marginTop: -.75, maxWidth: 80, marginRight: 0, paddingRight: 3 }}>
                                                                <Input
                                                                    sx={{ marginLeft: -4 }}
                                                                    value={customQuantity}
                                                                    size="small"
                                                                    onChange={handleInputChange}
                                                                    onBlur={handleBlur}
                                                                    inputProps={{
                                                                        step: 11,
                                                                        min: 0,
                                                                        type: 'number',
                                                                        'aria-labelledby': 'input-slider',
                                                                    }}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                        <Stack sx={{ marginLeft: 2, marginRight: 1 }} direction="row" alignItems="center" justifyContent="space-between">
                                                            <Typography>&nbsp;</Typography>
                                                            <Button size="small" variant="contained" sx={{ color: props.storefront.activeColors.addProductForm.buttonText, background: props.storefront.activeColors.addProductForm.buttonBackground, width: 60, marginTop: 1, marginBottom: 1 }} onClick={saveCustomQuantity}>Save</Button>
                                                        </Stack>
                                                    </Grid>
                                                </Popover>
                                                <IconButton sx={{ marginTop: -1, color: props.storefront.activeColors.addProductForm.iconForeground }} onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                    setAnchorEl(event.currentTarget);
                                                    setCustomQuantity(getLeadCount(prod.audienceID));
                                                    setCustomQuantityMax(getLeadCount(prod.audienceID));
                                                    setCustomQuantityName(prod.name);
                                                    setCustomAudienceID(prod.audienceID)
                                                }}>
                                                    <EditOutlinedIcon style={{ fontSize: 18 }} />
                                                </IconButton>
                                            </Stack>
                                            <Typography sx={{ marginTop: -1, marginRight: 1, color: props.storefront.activeColors.addProductForm.productText }}>&nbsp;{getLeadsPrice(prod)}</Typography>
                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>
                    }
                </React.Fragment>
                <Stack alignItems="center" sx={{ ...commonStyles, marginRight: 1 }}>
                    <Box alignItems="center" display="flex" justifyContent="center" sx={{ ...commonStyles, marginRight: 1 }} />
                    <Typography sx={{ color: props.storefront.activeColors.addProductForm.productText }}>Total leads selected: {totalLeadsSelected.toLocaleString()}</Typography>
                    <Button variant="contained" onClick={handleAddToCartButtonClick} sx={{ color: props.storefront.activeColors.addProductForm.buttonText, background: props.storefront.activeColors.addProductForm.buttonBackground, width: 150, marginTop: 1, marginBottom: 1 }}>Add to Cart</Button>
                    <Box display={showAddProductWarning} sx={{ marginLeft: 1 }}>
                        <Alert severity="error">{addProductWarningMsg}</Alert>
                    </Box>
                </Stack>
            </Stack>
        </React.Fragment>
    );
}
