import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import * as React from "react";
import Title from '../components/Title';
import { useEffect } from 'react';
import { Storefront } from "../models/StorefrontResponse";
import { Alert, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Switch, TextField, Typography } from "@mui/material";
import { Geography } from "../models/DataSource";
import geographyData from '../assets/data/geography.json';
import { useBlueprintCounts } from '../hooks/useBlueprintCounts';
import { Blueprint, BlueprintAccess, BlueprintResponse, QueryBlueprint, QueryFilter } from "../models/Blueprint";
import { generateOrderID, genUniqueId } from "../utils/GenerateUniqueID";
import { useWalletBalance } from "../hooks/useWalletBalance";
import Snackbar from "@mui/material/Snackbar";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { CartItem, ShoppingCart } from "../models/Store";
import { APIEndpoint, EndpointType, getEnvironment, httpPost, httpPostNoAuth } from "../utils/apiService";
import { CheckoutResponse } from "../models/CheckoutResponse";
import CloseIcon from "@mui/icons-material/Close";
import { useTSGUser } from "../contexts/TSGUserContext";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
    storefront: Storefront,
}

const Mortgage = (props: Props) => {
    const outputFields: string[] = ["first", "middle", "last", "address", "city", "state", "zip", "email", "phone", "phone_type", "phone_2", "phone_type_2", "situs_latitude", "situs_longitude", "date_of_birth", "exact_age", "ethnic_code", "mkt_total_value", "sale_date", "sale_price", "loan_to_value", "year_built", "living_square_feet", "bedrooms", "total_baths", "owner_occupied", "purchase_mortgage_date", "purchase_mortgage_term", "purchase_lender_name", "purchase_1st_mortgage_amount", "purchase_1st_mortgage_loan_type_code", "purchase_1st_mortgage_deed_type", "purchase_1st_mortgage_interest_rate_type", "purchase_1st_mortgage_interest_rate", "purchase_2nd_mortgage_amount", "purchase_2nd_mortgage_loan_type_code", "purchase_2nd_mortgage_deed_type", "purchase_2nd_mortgage_interest_rate_type", "purchase_2nd_mortgage_interest_rate", "most_recent_mortgage_loan_type_code", "most_recent_mortgage_deed_type", "most_recent_mortgage_interest_rate", "most_recent_mortgage_interest_rate_type", "most_recent_lender_name", "title_company_name"]
    const [selectedCounty, setSelectedCounty] = React.useState<string[]>([]);
    const [selectedState, setSelectedState] = React.useState<string>("");
    const [selectedIntRateStart, setSelectedIntRateStart] = React.useState<string>("");
    const [selectedIntRateEnd, setSelectedIntRateEnd] = React.useState<string>("");
    const [selectedLoanDateStart, setSelectedLoanDateStart] = React.useState<string>("");
    const [selectedLoanDateEnd, setSelectedLoanDateEnd] = React.useState<string>("");
    const [selectedLoanAmountStart, setSelectedLoanAmountStart] = React.useState<string>("");
    const [selectedLoanAmountEnd, setSelectedLoanAmountEnd] = React.useState<string>("");
    const [selectedLoanType, setSelectedLoanType] = React.useState<string[]>([]);
    const [selectedRateType, setSelectedRateType] = React.useState<string[]>([]);
    const [geography, setGeography] = React.useState<Geography>({ state: {}, county: {}, metro_area: {}, scf: {} });
    const [counties, setCounties] = React.useState<{ [key: string]: string }>({})
    const [buildQueryWarningMsg, setBuildQueryWarningMsg] = React.useState("");
    const [suppressOrders, setSuppressOrders] = React.useState(true);
    const [hasRunCount, setHasRunCount] = React.useState(false);
    const [blueprintCountRequest, setBlueprintCountRequest] = React.useState<QueryBlueprint>();
    const [customerID, setCustomerID] = React.useState("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [snackbarMsg, setSnackbarMsg] = React.useState("")
    const [countsBlueprint, setCountsBlueprint] = React.useState<QueryBlueprint>();
    const [savedBlueprint, setSavedBlueprint] = React.useState<Blueprint>()
    const [requireEmails, setRequireEmails] = React.useState(false);
    const [requirePhones, setRequirePhones] = React.useState(false);
    const [filterDNC, setFilterDNC] = React.useState(false);
    const [isDirty, setDirty] = React.useState(false)
    const [showEditLeads, setShowEditLeads] = React.useState(false)
    const [customLeadsCount, setCustomLeadsCount] = React.useState("0")

    // Hooks
    const { tsgUser } = useTSGUser();
    const { blueprintCount, blueprintCountLoading, blueprintCountError, blueprintSuppressionCount } = useBlueprintCounts(blueprintCountRequest, suppressOrders, customerID, "")
    const { walletBalance } = useWalletBalance(customerID, 1)
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/mortgage']);
        _hsq.push(['trackPageView']);
    }, []);

    useEffect(() => {
        // Load geography data 
        setGeography(geographyData as Geography);
    }, []);

    useEffect(() => {
        setCustomerID(tsgUser?.id || "")
    }, [tsgUser]);

    useEffect(() => {
        if (savedBlueprint && savedBlueprint?.id != "") {
            console.log("savedBlueprint:", savedBlueprint)
            submitOrder()
        }
    }, [savedBlueprint]);

    const handleStateValueChange = (event: SelectChangeEvent) => {
        const st = event.target.value as string
        setSelectedState(st)

        // Iterate over geography.county and only include ones where the state matches
        const filteredCounties = Object.entries(geography.county).filter(([, value]) => {
            return value.startsWith(st);
        });

        // Convert the filtered entries back to an object with the same type as geography.county
        const filteredCountyObject = filteredCounties.reduce((acc, [key, value]) => {
            acc[key] = value.substring(3);
            return acc;
        }, {} as { [key: string]: string });


        setCounties(filteredCountyObject)
    }

    const handleCountyValueChange = (event: SelectChangeEvent<typeof selectedCounty>) => {
        if (event.target.value.includes("all")) {
            setSelectedCounty(Object.keys(counties))
            return
        }
        const { target: { value }, } = event;
        setSelectedCounty(typeof value === 'string' ? value.split(',') : value)
        setDirty(true)
    }

    const handleLoanTypeValueChange = (event: SelectChangeEvent<typeof selectedLoanType>) => {
        const { target: { value }, } = event;
        setSelectedLoanType(typeof value === 'string' ? value.split(',') : value);
        setDirty(true)
    }

    const handleRateTypeValueChange = (event: SelectChangeEvent<typeof selectedRateType>) => {
        const { target: { value }, } = event;
        setSelectedRateType(typeof value === 'string' ? value.split(',') : value);
        setDirty(true)
    }

    const intRateStartValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[,/#!$%^&*;:{}=\-_`~()]/g, '');

        setSelectedIntRateStart(cleanValue);
        setDirty(true)
    }

    const intRateEndValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[,/#!$%^&*;:{}=\-_`~()]/g, '');

        setSelectedIntRateEnd(cleanValue);
        setDirty(true)
    }

    const loanAmountStartValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');

        setSelectedLoanAmountStart(cleanValue);
        setDirty(true)
    }

    const loanAmountEndValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');

        setSelectedLoanAmountEnd(cleanValue);
        setDirty(true)
    }

    const loanDateStartValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');

        setSelectedLoanDateStart(formatDate(cleanValue));
        setDirty(true)
    }

    const loanDateEndValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');

        setSelectedLoanDateEnd(formatDate(cleanValue));
        setDirty(true)
    }

    const requireEmailsChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRequireEmails(checked)
        setDirty(true)
    }

    const requirePhonesChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRequirePhones(checked)
        setDirty(true)
    }

    const filterDNCChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFilterDNC(checked)
        setDirty(true)
    }

    const suppressOrdersChanged = () => {
        setSuppressOrders(!suppressOrders)
    }

    function formatDate(dateString: string): string {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        return `${year}-${month}-${day}`;
    }

    const runCountsButtonClicked = () => {
        const newBlueprint = assembleBlueprint()
        if (newBlueprint == undefined) {
            return
        }

        setHasRunCount(true)
        setBlueprintCountRequest(newBlueprint)
        setDirty(false)
        setCustomLeadsCount("0")
    }

    const buyNowWithWalletButtonClicked = () => {
        buyNowClicked()
    }

    const buyNowClicked = () => {
        if (blueprintCount == 0) {
            setSnackbarMsg("No leads were found in the specified query. Please adjust your query and click the 'Run Counts' button")
            setOpenSnackbar(true)
            return
        }

        if (!isAuthenticated) {
            alert("It appears you are no longer authenticated. Please login again and retry the purchase.")
            navigate("/signin")
        }

        if (isDirty) {
            setSnackbarMsg("You must 'Run Counts' before making a purchase")
            setOpenSnackbar(true)
            return
        }

        saveBlueprint()
    }

    const submitOrder = () => {
        if (!savedBlueprint) {
            return
        }

        const shoppingCart: ShoppingCart = {
            cartToken: genUniqueId(),
            createdAt: "",
            modifiedAt: "",
            hubspotDealID: "",
            subtotal: calcSubtotal(),
            total: calcTotal(),
            discount: 0,
            couponCode: "",
            items: [],
            status: "shopping",
            userID: tsgUser?.id || "",
            storefrontID: props.storefront.storeName,
            discountDescription: "",
            orderID: generateOrderID(),
            applyWallet: appliedWalletCredits() > 0,
            walletCreditsApplied: appliedWalletCredits()
        }

        const cartItem: CartItem = {
            id: genUniqueId(),
            quantity: calcTotalLeads(),
            postalCodes: [],
            product: {
                audienceID: 0,
                description: "Custom Mortgage Leads",
                detailedDescription: savedBlueprint.name,
                id: savedBlueprint.id,
                name: "Custom Mortgage Leads",
                price: .2,
                productType: "custom_leads",
                listCounts: [],
                active: true,
                stripeID: getStripeCustomProductID(),
                createdAt: "",
                modifiedAt: "",
                groupName: "",
                infoURL: "",
                priceInfo: {
                    price: .2,
                    stripePriceID: getLeadStripePriceID()
                },
                outputFields: [],
                features: {
                    noDNC: false,
                    phonesEmails: false,
                },
                stripePrice: getLeadStripePriceID(),
                leadType: "",
            }
        }

        shoppingCart.items.push(cartItem)

        if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
            shoppingCart.storefrontID = props.storefront.storeName
        }

        if (shoppingCart.storefrontID == undefined || shoppingCart.storefrontID == "") {
            console.log("Cannot checkout with storefront! The storefront name could not be determined")
            setSnackbarMsg("Cannot checkout with storefront! The storefront name could not be determined. Are you logged-in?")
            setOpenSnackbar(true)
            return
        }

        if (isAuthenticated) {
            shoppingCart.userID = tsgUser?.id || ""
        } else {
            console.log("Cannot checkout without user! The user could not be determined")
            setSnackbarMsg("Cannot checkout at this time! The user could not be determined. Are you logged-in?")
            setOpenSnackbar(true)
            return
        }

        const apiURL = APIEndpoint(EndpointType.Checkout)
        httpPostNoAuth(apiURL, JSON.stringify(shoppingCart))
            .then((data) => {
                const checkoutResponse = data as CheckoutResponse;

                if (checkoutResponse.status == 'error') {
                    console.log("Error checking-out: " + checkoutResponse.errorMessage)
                    setSnackbarMsg("Error Checking out. " + checkoutResponse.errorMessage)
                    setOpenSnackbar(true)
                } else {
                    if (appliedWalletCredits() >= calcTotalLeads()) {
                        // The wallet handled everything so just redirect to the orders page
                        navigate("/myorders")
                        return
                    }
                    console.log("Checkout session created successfuly...redirecting to checkout page")

                    // Redirect to the checkout page
                    window.location.replace(checkoutResponse.redirectURL);

                }
            })
            .catch((error) => {
                console.log("saveShoppingCart: " + error.message)
            });
    }

    const saveBlueprint = () => {
        if (!countsBlueprint) {
            setSnackbarMsg("Unable to save query blueprint because it doesn't exist")
            setOpenSnackbar(true)
            return
        }

        const bpAccess: BlueprintAccess = {
            scope: "user",
            value: tsgUser?.id || ""
        }

        const bprint: Blueprint = {
            id: "",
            name: createBlueprintName(),
            queryBlueprint: countsBlueprint,
            createdBy: "",
            createdAt: "",
            modifiedAt: "",
            access: bpAccess
        }

        bprint.queryBlueprint.output_fields = outputFields

        const apiURL = APIEndpoint(EndpointType.Blueprints)
        httpPost(apiURL, JSON.stringify(bprint))
            .then((data) => {
                const response = data as BlueprintResponse;

                if (response.status == 'error') {
                    console.log("Error saving blueprint: " + response.errorMessage)
                    setSnackbarMsg("Failed to save Blueprint..." + response.errorMessage || "Unknown error")
                    setOpenSnackbar(true)
                } else {
                    console.log("Blueprint saved successfully!")
                    setSavedBlueprint(response.blueprint)
                }
            })
            .catch((error) => {
                setSnackbarMsg(error.message)
                setOpenSnackbar(true)
            });
    }

    const getStripeCustomProductID = (): string => {
        if (getEnvironment() == "dev") {
            return "prod_Pv636v3I3I6hgr"
        }

        return "prod_Pv65QabwUkOvYl"
    }

    function getLeadStripePriceID(): string {
        if (getEnvironment() == "dev") {
            return "price_1PVJvkCxNi5DvLVrvxMwE0zk"
        } else {
            return "price_1PVK4gCxNi5DvLVrL090ac26"
        }
    }

    const createBlueprintName = (): string => {
        let name = "Mortgage: County(" + selectedCounty.join(",") + ")"

        if (selectedLoanType.length > 0) {
            name += " Loan(" + selectedLoanType.join(",") + ")"
        }

        if (selectedRateType.length > 0) {
            name += " Rate(" + selectedRateType.join(",") + ")"
        }

        if (selectedIntRateStart != "" && selectedIntRateEnd != "") {
            name += " Int(" + selectedIntRateStart + "-" + selectedIntRateEnd + ")"
        }

        if (selectedLoanAmountStart != "" && selectedLoanAmountEnd != "") {
            name += " Amount(" + selectedLoanAmountStart + "-" + selectedLoanAmountEnd + ")"
        }

        return name
    }


    const assembleBlueprint = (): QueryBlueprint | undefined => {
        setBuildQueryWarningMsg("")
        const filters = assembleFilters()

        if (filters.length == 0) {
            return undefined
        }


        const newBlueprint: QueryBlueprint = {
            name: tsgUser?.lastName + "_" + genUniqueId() + "_lb",
            data_source_id: 31,
            match_type: "",
            filters: assembleFilters(),
            dedupe_within: "",
            output_fields: [],
            select_type: "address_zip",
            phone_type: "wireless_priority",
            dnc: filterDNC,
        }

        if (requireEmails) {
            newBlueprint.emails = "all"
        }

        if (requirePhones) {
            newBlueprint.phones = "all"
        }


        console.log("Blueprint:", newBlueprint)
        setCountsBlueprint(newBlueprint)
        return newBlueprint
    }

    const assembleFilters = (): QueryFilter[] => {
        const filters: QueryFilter[] = []

        if (selectedCounty.length == 0) {
            setBuildQueryWarningMsg("At least one County must be selected")
            return []
        } else {
            const newFilter: QueryFilter = { field: "state_countycode", condition: "and", values: selectedCounty, omit: false }
            filters.push(newFilter)
        }

        if (selectedLoanType.length > 0) {
            const newFilter: QueryFilter = { field: "most_recent_mortgage_loan_type_code", condition: "and", values: selectedLoanType, omit: false }
            filters.push(newFilter)
        }

        if (selectedRateType.length > 0) {
            const newFilter: QueryFilter = { field: "most_recent_mortgage_interest_rate_type", condition: "and", values: selectedRateType, omit: false }
            filters.push(newFilter)
        }

        // Interest Rate Range
        if (selectedIntRateStart != "" && selectedIntRateEnd == "") {
            setBuildQueryWarningMsg("An interest rate start value was specified but no end. Please specify an ending value.")
            return []
        }

        if (selectedIntRateStart == "" && selectedIntRateEnd != "") {
            setBuildQueryWarningMsg("An interest rate end value was specified but no start. Please specify a starting value.")
            return []
        }

        if (selectedIntRateStart != "" && selectedIntRateEnd != "") {
            const newFilter: QueryFilter = { field: "most_recent_mortgage_interest_rate", condition: "and", values: [selectedIntRateStart, selectedIntRateEnd], omit: false }
            filters.push(newFilter)
        }

        // Loan Amount Range
        if (selectedLoanAmountStart != "" && selectedLoanAmountEnd == "") {
            setBuildQueryWarningMsg("A loan amount start value was specified but no end. Please specify an ending value.")
            return []
        }

        if (selectedLoanAmountStart == "" && selectedLoanAmountEnd != "") {
            setBuildQueryWarningMsg("A loan amount end value was specified but no start. Please specify a starting value.")
            return []
        }

        if (selectedLoanAmountStart != "" && selectedLoanAmountEnd != "") {
            const newFilter: QueryFilter = { field: "most_recent_mortgage_amount", condition: "and", values: [selectedLoanAmountStart, selectedLoanAmountEnd], omit: false }
            filters.push(newFilter)
        }

        // Loan Date Range
        if (selectedLoanDateStart != "" && selectedLoanDateEnd == "") {
            setBuildQueryWarningMsg("A loan start date was specified but no end. Please specify an end date.")
            return []
        }

        if (selectedLoanDateStart == "" && selectedLoanDateEnd != "") {
            setBuildQueryWarningMsg("A loan end date was specified but no start. Please specify a start date.")
            return []
        }

        if (selectedLoanDateStart != "" && selectedLoanDateEnd != "") {
            const newFilter: QueryFilter = { field: "most_recent_mortgage_date", condition: "and", values: [selectedLoanDateStart, selectedLoanDateEnd], omit: false }
            filters.push(newFilter)
        }

        //if (filterDNC) {
        //    const dnc: QueryFilter = { field: "dnc", condition: "and", values: ["Y"], omit: false }
        //    filters.push(dnc)

        //    // DNC2 was removed because it was causing a 500 internal server error from LG
        //    //const dnc2: QueryFilter = { field: "dnc_2", condition: "and", values: ["Y"], omit: false }
        //    //filters.push(dnc2)
        //}

        // Land Use - This field is hidden from the user and 
        const landUseValues: string[] = ["2", "7", "6", "10", "34", "27", "32", "1", "31", "5", "26", "8", "11", "12"]
        const newFilter: QueryFilter = { field: "land_use", condition: "and", values: landUseValues, omit: false }
        filters.push(newFilter)

        return filters
    }

    const calcTotalLeads = (): number => {
        const customCount = getCustomLeadsCount()
        if (customCount == 0) {
            return blueprintCount
        }

        return customCount
    }

    const calcSubtotal = (): number => {
        const customCount = getCustomLeadsCount()
        if (customCount == 0) {
            return blueprintCount * .2
        }

        return customCount * .2
    }

    const getCustomLeadsCount = (): number => {
        const customCount = parseInt(customLeadsCount)
        if (isNaN(customCount)) {
            return 0
        }

        return customCount
    }

    const appliedWalletCredits = (): number => {
        let wallFunds = walletBalance?.balance || 0

        const customCount = getCustomLeadsCount()
        let leadsCount = blueprintCount
        if (customCount > 0) {
            leadsCount = customCount
        }

        if (wallFunds >= leadsCount) {
            wallFunds = leadsCount
        } else {
            wallFunds = walletBalance?.balance || 0
        }

        return wallFunds
    }

    const calcTotal = (): number => {
        const customCount = getCustomLeadsCount()
        let leadsCount = blueprintCount
        if (customCount > 0) {
            leadsCount = customCount
        }
         
        return (leadsCount - appliedWalletCredits()) * .2
    }

    const editCustomLeadsCount = () => {
        setShowEditLeads(true)
        setCustomLeadsCount(blueprintCount.toString())
    }

    const updateCustomLeadsCount = () => {
        setShowEditLeads(false)
    }

    const cancelCustomLeadsCount = () => {
        setCustomLeadsCount("0")
        setShowEditLeads(false)
    }

    const customLeadsCountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const customCount = parseInt(event.target.value)
        if (isNaN(customCount)) {
            setCustomLeadsCount("0")
            return
        }

        if (customCount > blueprintCount) {
            setCustomLeadsCount(blueprintCount.toString())
            return
        }

        setCustomLeadsCount(event.target.value)
    }

    const calcPotentialWalletSavings = (): number => {
        const customCount = getCustomLeadsCount()
        let leadsCount = blueprintCount
        if (customCount > 0) {
            leadsCount = customCount
        }

        if (leadsCount < 2500) {
            return 0
        }

        if (leadsCount < 5000) {
            return calcTotal() - (leadsCount * .18)
        }

        if (leadsCount < 10000) {
            return calcTotal() - (leadsCount * .16)
        }

        if (leadsCount >= 10000) {
            return calcTotal() - (leadsCount * .14)
        }

        return 0
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const friendlyLeadsCount = (): string => {
        const customCount = getCustomLeadsCount()

        if (customCount > 0) {
            return `${customCount.toLocaleString()} of ${blueprintCount.toLocaleString()}`
        }
        
        return `${blueprintCount.toLocaleString()}`
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Title>Mortgage Leads</Title>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                                <Typography variant="h5">Customize mortgage-based leads with the criteria that is important to you!</Typography>

                                <Paper elevation={3} sx={{ mt: 3 }}>
                                    <Stack
                                        sx={{
                                            mt: 2, ml: 2, mb: 2
                                        }}
                                        spacing={2}
                                    >

                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography sx={{ width: 100 }}>Geography:</Typography>
                                            <FormControl fullWidth sx={{ width: 300 }}>
                                                <InputLabel>State</InputLabel>
                                                <Select label="Values" onChange={handleStateValueChange} value={selectedState} MenuProps={{
                                                    sx: {
                                                        "&& .Mui-selected": {
                                                            backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                        }
                                                    }
                                                }}>
                                                    {Object.entries(geography.state).map(([key, value]) => (
                                                        <MenuItem value={key} key={key}>{value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth sx={{ width: 300 }}>
                                                <InputLabel>County</InputLabel>
                                                <Select label="Values" multiple onChange={handleCountyValueChange} value={selectedCounty} MenuProps={{
                                                    sx: {
                                                        "&& .Mui-selected": {
                                                            backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                        }
                                                    }
                                                }}>
                                                    <MenuItem value="all" key="all">All Counties</MenuItem>
                                                    {Object.entries(counties).map(([key, value]) => (
                                                        <MenuItem value={key} key={key}>{value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Stack>

                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography sx={{ width: 100 }}>Loan Type:</Typography>
                                            <FormControl fullWidth sx={{ width: 300 }}>
                                                <InputLabel>Loan Type</InputLabel>
                                                <Select label="Values" multiple onChange={handleLoanTypeValueChange} value={selectedLoanType} MenuProps={{
                                                    sx: {
                                                        "&& .Mui-selected": {
                                                            backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                        }
                                                    }
                                                }}>
                                                    <MenuItem value="CONVENTIONAL" key="CONVENTIONAL">Conventional</MenuItem>
                                                    <MenuItem value="VA" key="VA">VA</MenuItem>
                                                    <MenuItem value="FHA" key="FHA">FHA</MenuItem>
                                                    <MenuItem value="COMMUNITY DEVLOPMENT AUTHORITY" key="COMMUNITY DEVLOPMENT AUTHORITY">Community Development Authority</MenuItem>
                                                    <MenuItem value="CONSTRUCTION" key="CONSTRUCTION">Construction</MenuItem>
                                                    <MenuItem value="LEASE HOLD MORTGAGE" key="LEASE HOLD MORTGAGE">Lease Hold Mortgage</MenuItem>
                                                    <MenuItem value="PURCHASE MONEY MORTGAGE" key="PURCHASE MONEY MORTGAGE">Purchase Money Mortgage</MenuItem>
                                                    <MenuItem value="PRIVATE PARTY LENDER" key="PRIVATE PARTY LENDER">Private Party Lender</MenuItem>
                                                    <MenuItem value="SMALL BUSINESS ADMINISTRATION" key="SMALL BUSINESS ADMINISTRATION">Small Business Administration</MenuItem>
                                                    <MenuItem value="WRAP-AROUND MORTGAGE" key="WRAP-AROUND MORTGAGE">Wrap-Around Mortgage</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth sx={{ width: 300 }}>
                                                <InputLabel>Rate Type</InputLabel>
                                                <Select label="Values" multiple onChange={handleRateTypeValueChange} value={selectedRateType} MenuProps={{
                                                    sx: {
                                                        "&& .Mui-selected": {
                                                            backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                        }
                                                    }
                                                }}>
                                                    <MenuItem value="ADA" key="ADA">Adjustable, interest rate from national average</MenuItem>
                                                    <MenuItem value="ADJ" key="ADJ">Adjustable</MenuItem>
                                                    <MenuItem value="BAL" key="BAL">Balloon</MenuItem>
                                                    <MenuItem value="FIX" key="FIX">Fixed</MenuItem>
                                                    <MenuItem value="FXA" key="FXA">Fixed, interest rate from national average</MenuItem>
                                                    <MenuItem value="VAR" key="VAR">Variable Rate</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography sx={{ width: 160 }}>Interest Rate Range:</Typography>
                                            <TextField fullWidth sx={{ width: 140 }} label="Rate Start" variant="outlined" value={selectedIntRateStart} onChange={intRateStartValueChanged} />
                                            <Typography sx={{}}>-</Typography>
                                            <TextField fullWidth sx={{ width: 140 }} label="Rate End" variant="outlined" value={selectedIntRateEnd} onChange={intRateEndValueChanged} />
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography sx={{ width: 160 }}>Loan Amount Range:</Typography>
                                            <TextField fullWidth sx={{ width: 140 }} label="Amount Start" variant="outlined" value={selectedLoanAmountStart} onChange={loanAmountStartValueChanged} />
                                            <Typography sx={{}}>-</Typography>
                                            <TextField fullWidth sx={{ width: 140 }} label="Amount End" variant="outlined" value={selectedLoanAmountEnd} onChange={loanAmountEndValueChanged} />
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography sx={{ width: 160 }}>Loan Date Range:</Typography>
                                            <TextField type="date" fullWidth sx={{ width: 180 }} variant="outlined" value={selectedLoanDateStart} onChange={loanDateStartValueChanged} />
                                            <Typography sx={{}}>-</Typography>
                                            <TextField type="date" fullWidth sx={{ width: 180 }} variant="outlined" value={selectedLoanDateEnd} onChange={loanDateEndValueChanged} />
                                        </Stack>
                                        <Stack direction="row">
                                            <FormControlLabel control={<Switch checked={requireEmails} onChange={requireEmailsChanged} />} label="Require Emails" />
                                            <FormControlLabel control={<Switch checked={requirePhones} onChange={requirePhonesChanged} sx={{ ml: 4 }} />} label="Require Phones" />
                                            <FormControlLabel control={<Switch checked={filterDNC} onChange={filterDNCChanged} sx={{ ml: 4 }} />} label="Remove DNC Phones" />
                                        </Stack>
                                        <Typography variant="body2">Tip: Leave fields blank if you don't want to include it in your search. Want more search options...use the "List Builder"</Typography>
                                    </Stack>
                                </Paper>

                                <Paper elevation={3} sx={{ mt: 3 }}>
                                    <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>Check Available Leads</Typography>
                                    {buildQueryWarningMsg != "" && <Alert sx={{ m: 1 }} severity="warning">{buildQueryWarningMsg}</Alert>}
                                    <Stack direction="row" sx={{ ml: 2, mb: 2 }}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <FormControlLabel control={<Switch defaultChecked={suppressOrders} onChange={suppressOrdersChanged} />} label="Suppress Counts from Previous Orders" />
                                            <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={runCountsButtonClicked}>Run Counts</Button>
                                        </Stack>
                                    </Stack>
                                    {blueprintCountLoading
                                        ? <Typography sx={{ m: 2 }}>{blueprintCountLoading ? "Requesting counts..." : ""}</Typography>
                                        : blueprintCountError != ""
                                            ? <Alert severity="error" sx={{ m: 2 }}>{blueprintCountError}</Alert>
                                            : hasRunCount &&
                                            <Stack>
                                                <Stack direction="row">
                                                    <Typography sx={{ ml: 2, mb: 2 }}>Count Result: {blueprintCount.toLocaleString()}</Typography>
                                                    {suppressOrders && <Typography sx={{ ml: 1, mb: 2 }}>- After suppressing {blueprintSuppressionCount} previous orders</Typography>}
                                                </Stack>
                                            </Stack>
                                    }
                                </Paper>

                                {blueprintCount > 0 &&
                                    <Paper elevation={3} sx={{ mt: 3 }}>
                                        <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>Purchase Leads</Typography>
                                        <Stack>
                                            <Stack direction="row" sx={{ ml: 2 }} >
                                                <Typography sx={{ width: 170 }}>Leads:</Typography>
                                                <Typography>{friendlyLeadsCount()}</Typography>
                                                {showEditLeads
                                                    ? <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 2 }}>
                                                        <TextField fullWidth sx={{ width: 140 }} label="Leads" variant="outlined" size="small" value={customLeadsCount} onChange={customLeadsCountChanged} />
                                                        <Button variant="contained" color="primary" onClick={updateCustomLeadsCount}>Update</Button>
                                                        <Button variant="contained" color="primary" onClick={cancelCustomLeadsCount}>Cancel</Button>
                                                    </Stack>
                                                    : <IconButton sx={{ marginTop: -1, color: props.storefront.activeColors.mainPage.pageText }} onClick={editCustomLeadsCount}>
                                                        <EditOutlinedIcon style={{ fontSize: 18 }} />
                                                    </IconButton>
                                                }
                                            </Stack>
                                            <Stack direction="row" sx={{ ml: 2 }}>
                                                <Typography sx={{ width: 170 }}>Price/Lead:</Typography>
                                                <Typography>$0.20</Typography>
                                            </Stack>
                                            {walletBalance && walletBalance.balance && walletBalance.balance > 0 &&
                                                <Stack direction="row" sx={{ ml: 2 }}>
                                                    <Typography sx={{ width: 170 }}>Wallet Balance:</Typography>
                                                    <Typography>{walletBalance?.balance.toLocaleString()}</Typography>
                                                </Stack>
                                            }

                                            {walletBalance && walletBalance.balance && walletBalance.balance >= blueprintCount
                                                ? <Button variant="contained" color="primary" sx={{ m: 2, width: 220 }} onClick={buyNowWithWalletButtonClicked}>Buy Now with Wallet</Button>
                                                : <Stack sx={{ ml: 2 }}>
                                                    <Stack direction="row">
                                                        <Typography sx={{ width: 170 }}>Sub-Total:</Typography>
                                                        <Typography>{formatter.format(calcSubtotal())}</Typography>
                                                    </Stack>
                                                    <Stack direction="row">
                                                        <Typography sx={{ width: 170 }}>Wallet Credits Used:</Typography>
                                                        <Typography>{appliedWalletCredits().toLocaleString()}</Typography>
                                                    </Stack>
                                                    <Stack direction="row">
                                                        <Typography sx={{ width: 170 }}>Total:</Typography>
                                                        <Typography>{formatter.format(calcTotal())}</Typography>
                                                    </Stack>
                                                    {calcPotentialWalletSavings() > 0 &&
                                                        <Alert sx={{ ml: 2, mr: 3, mt: 1 }} severity="info">Savings Alert! You can save {formatter.format(calcPotentialWalletSavings())} on this purchase by funding your wallet first. Click 'Wallet' in the left menu for more information.</Alert>
                                                    }
                                                    <Button variant="contained" color="primary" sx={{ m: 2, width: 220 }} onClick={buyNowClicked}>Buy Now</Button>
                                                </Stack>
                                            }
                                        </Stack>
                                    </Paper>
                                }

                            </Paper>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
        </Container>
    );
};

export default Mortgage;