/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useRef, useState, useEffect } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';
import { Alert, Box, Typography } from '@mui/material';
import * as turf from "@turf/turf";
import { Storefront } from '../models/StorefrontResponse';
//import { useForwardGeocoding } from '../hooks/useForwardGeocoding';
import LoadingSpinner from './LoadingSpinner';
import { useZipCodeCounts } from '../hooks/useZipCodeCounts';

interface CartProps {
  storefront: Storefront
  radiusDistance: number
  zipCode: string
}

const RadiusMap = (props: CartProps) => {
  const [storefront, setStorefront] = useState<Storefront>();
  const [radiusDistance, setRadiusDistance] = useState(props.radiusDistance)
  const radiusDistanceRef = React.useRef(radiusDistance)
  const currentPointRef = React.useRef([0, 0])

  const map = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const boundarySoureLayer = "zip_boundaries_20240226"
  //const { coordinates, locationName, geocodingError, geocodingLoading } = useForwardGeocoding(props.zipCode)
  const { zipCodeCounts, zipCodeError, zipCodeLoading} = useZipCodeCounts(props.zipCode, "", "", "", true, 0, "")

  useEffect(() => {
    radiusDistanceRef.current = radiusDistance
    setCircle(radiusDistanceRef.current)
    map.current?.zoomTo(getZoomLevel(radiusDistanceRef.current))
  }, [radiusDistance])

  useEffect(() => {
    setRadiusDistance(props.radiusDistance)
  }, [props])

  const setCircle = (distance: number) => {

    const _center = turf.point(currentPointRef.current);
    const _radius = distance;
    const _options = {
      steps: 80
    };

    const _circle = turf.circle(_center, _radius, _options);

    const radiusRangeSource = map.current?.getSource('radiusRange') as mapboxgl.GeoJSONSource;
    if (radiusRangeSource != undefined) {
      radiusRangeSource.setData(_circle)
    }
  }

  // useEffect(() => {
  //   if (coordinates.latitude != 0 && coordinates.longitude != 0) {
  //     map.current?.flyTo({ center: [coordinates.longitude, coordinates.latitude], essential: true, zoom: getZoomLevel(props.radiusDistance) });
  //     currentPointRef.current = [coordinates.longitude, coordinates.latitude]
  //   }
  // }, [coordinates])

  useEffect(() => {
    if (zipCodeCounts.length > 0 && zipCodeCounts[0].lat != "" && zipCodeCounts[0].lng != "") {
      const coordinates = { latitude: parseFloat(zipCodeCounts[0].lat), longitude: parseFloat(zipCodeCounts[0].lng) }
      map.current?.flyTo({ center: [coordinates.longitude, coordinates.latitude], essential: true, zoom: getZoomLevel(props.radiusDistance) });
      currentPointRef.current = [coordinates.longitude, coordinates.latitude]
    }
  }, [zipCodeCounts])

  useEffect(() => {
    setStorefront(props.storefront)
    const token = process.env.REACT_APP__MAPBOX_TOKEN;

    if (token != undefined) {
      //setMapboxAccessToken(token);
      mapboxgl.accessToken = token;
    }
  })


  useEffect(() => {
    if (!('geolocation' in navigator)) {
      console.log("location services not available")
    } else {
      navigator.geolocation.getCurrentPosition(
        onSuccess,
        onError,
        { enableHighAccuracy: false, timeout: 10000, maximumAge: 18000000 },
      );
    }
  }, []);

  function getZoomLevel(radius: number): number {
    if (props.zipCode == "") {
      return 4
    }

    if (radius <= 1) {
      return 13.5
    } else if (radius <= 2) {
      return 12.5
    } else if (radius <= 3) {
      return 12
    } else if (radius <= 4) {
      return 11.55
    } else if (radius <= 5) {
      return 11.25
    } else if (radius <= 6) {
      return 11
    } else if (radius <= 7) {
      return 10.75
    } else if (radius <= 8) {
      return 10.55
    } else if (radius <= 9) {
      return 10.35
    } else if (radius <= 10) {
      return 10.3
    } else if (radius <= 11) {
      return 10.15
    } else if (radius <= 12) {
      return 10.05
    } else if (radius <= 13) {
      return 9.93
    } else if (radius <= 14) {
      return 9.8
    } else if (radius <= 15) {
      return 9.73
    } else if (radius <= 16) {
      return 9.64
    } else if (radius <= 17) {
      return 9.55
    } else if (radius <= 18) {
      return 9.45
    } else if (radius <= 19) {
      return 9.35
    } else if (radius <= 20) {
      return 9.25
    } else if (radius <= 25) {
      return 9
    } else if (radius <= 30) {
      return 8.72
    } else if (radius <= 40) {
      return 8.25
    } else if (radius <= 50) {
      return 8
    } else if (radius <= 75) {
      return 7.4
    } else if (radius <= 100) {
      return 7
    } else if (radius <= 150) {
      return 6.4
    } else if (radius <= 250) {
      return 5.65
    } else if (radius <= 500) {
      return 4.8
    } else if (radius <= 1000) {
      return 4.2
    }

    return 9
  }

  const getLocationName = (): string => {
    if (zipCodeCounts.length > 0) {
      return zipCodeCounts[0].cityCap + ", " + zipCodeCounts[0].state + " " + zipCodeCounts[0].zipCode
    }

    return ""
  }

  // mapbox://styles/danpenn/clirqto4000i201pu8jx4cz9v (Light Style)
  // mapbox://styles/danpenn/clq1wcjyt00la01r72k8917mv (Dark Style)
  useEffect(() => {
    let mapboxStyle = "mapbox://styles/danpenn/clirqto4000i201pu8jx4cz9v"
    const colorMode = localStorage.getItem('colorMode')
    if (colorMode == "dark") {
      mapboxStyle = "mapbox://styles/danpenn/clq1wcjyt00la01r72k8917mv"
    }


    if (map.current) return; // initialize map only once
    console.log("Initializing Map...")

    let lng = 0
    let lat = 0

    if (zipCodeCounts.length > 0 && zipCodeCounts[0].lat != "" && zipCodeCounts[0].lng != "") {
      lng = parseFloat(zipCodeCounts[0].lng)
      lat = parseFloat(zipCodeCounts[0].lat)
    }

    if (lng == 0 && lat == 0) {
      lng = -98.5795
      lat = 39.8283
    }

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: mapboxStyle,
      center: [lng, lat],
      zoom: getZoomLevel(props.radiusDistance),
    });

    map.current.on('load', function () {
      // The feature-state dependent fill-opacity expression will render the hover effect
      // when a feature's hover state is set to true.
      if (map.current == null) {
        return
      }

      const _center = turf.point([-122.0616526, 47.7275042]);
      const _radius = 0;
      const _options = {
        steps: 80
      };

      const _circle = turf.circle(_center, _radius, _options);

      map.current.addSource("radiusRange", {
        type: "geojson",
        data: _circle
      });

      map.current.addLayer({
        id: "circle-fill",
        type: "fill",
        source: "radiusRange",
        paint: {
          "fill-color": "#24fc03",
          "fill-opacity": .2,
        },
      });

      map.current.addSource("zips", {
        "type": "vector",
        "url": "mapbox://danpenn.b5k2x2yo"
      });

      map.current.addLayer({
        'id': 'zip-fills',
        'type': 'fill',
        'source': 'zips',
        'source-layer': boundarySoureLayer,
        'layout': {},
        'paint': {
          'fill-color': '#24fc03',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.15,
            0
          ]
        }
      });
    });
  }, [storefront, radiusDistance]);

  const onSuccess = (location: GeolocationPosition) => {
    map.current?.flyTo({ center: [location.coords.longitude, location.coords.latitude], essential: true, zoom: getZoomLevel(props.radiusDistance) });
  }

  const onError = (error: GeolocationPositionError) => {
    console.log("location error: ", error)
  }

  return (
    <div style={{  }}>
      <Typography variant="body1">{getLocationName()}</Typography>
      <Box ref={mapContainerRef} sx={{height: "400px", width: "100%"}} />
      {zipCodeError != "" &&
        <Alert severity="error" sx={{ mt: 2 }}>{zipCodeError}</Alert>
      }
      {zipCodeLoading &&
        <LoadingSpinner title={'Getting Zip Code coordinates'} textColor={props.storefront.activeColors.mainPage.pageText} />
      }
    </div>
  );
}

export default RadiusMap;
