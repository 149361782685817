/**
 * Color with alpha channel
 */
export function varAlpha(color: string, opacity = 1) {
    // if color starts with #, it's a hex color, so convert it to RGB channels
    if (color.startsWith('#')) {
      color = hexToRgbChannel(color);
    }

    const unsupported =
      color.startsWith('#') ||
      color.startsWith('rgb') ||
      color.startsWith('rgba') ||
      (!color.includes('var') && color.includes('Channel'));
  
    if (unsupported) {
      throw new Error(
        `[Alpha]: Unsupported color format "${color}".
         Supported formats are:
         - RGB channels: "0 184 217".
         - CSS variables with "Channel" prefix: "var(--palette-common-blackChannel, #000000)".
         Unsupported formats are:
         - Hex: "#00B8D9".
         - RGB: "rgb(0, 184, 217)".
         - RGBA: "rgba(0, 184, 217, 1)".
         `
      );
    }
  
    return `rgba(${color} / ${opacity})`;
  }

/**
 * Converts a hex color to RGB channels
 */
export function hexToRgbChannel(hex: string) {
  if (!/^#[0-9A-F]{6}$/i.test(hex)) {
    throw new Error(`Invalid hex color: ${hex}`);
  }

  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `${r} ${g} ${b}`;
}