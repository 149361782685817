import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import Layout from "./components/Layout";
import "./styles/globals.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import { HelmetProvider } from 'react-helmet-async';
import { LogProvider } from "./contexts/LogContext";
import { TSGUserContextProvider } from "./contexts/TSGUserContext";


function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <TSGUserContextProvider>
            <LogProvider>
              <Routes>
                {appRoutes.map((route) => (
                  <Route
                    key={route.key}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </LogProvider>
          </TSGUserContextProvider>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;
